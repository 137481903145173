import React, { useState, useEffect } from "react";
import "./Groups.css";

export interface MySelectFormProps {
  isEditing: boolean;
  SelectData: {
    groupName: string;
    isMobilePresenceCB?: boolean;
    isTeamsPresenceCB?: boolean;
    isCheckboxChecked: boolean;
    mobileSelect1: string;
    mobileSelect2: string;
    mobileSelect3: string;
    isSamwinCheckboxChecked: boolean;
    samwinSelect1: string;
    samwinSelect2: string;
    samwinSelect3: string;
  };
  tableId: number;
}

const SelectForm: React.FC<MySelectFormProps> = ({
  isEditing,
  SelectData,
  tableId,
}: MySelectFormProps) => {
  const [groupName, setGroupName] = useState(
    isEditing ? SelectData.groupName : ""
  );
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(
    isEditing ? SelectData.isCheckboxChecked : false
  );
  const [isMobilePresenceCB, setIsMobilePresenceCB] = useState(
    isEditing ? SelectData.isMobilePresenceCB : false
  );
  const [isTeamsPresenceCB, setIsTeamsPresenceCB] = useState(
    isEditing ? SelectData.isTeamsPresenceCB : false
  );
  const [mobileSelect1, setMobileSelect1] = useState(
    isEditing ? SelectData.mobileSelect1 : "-1"
  );
  const [mobileSelect2, setMobileSelect2] = useState(
    isEditing ? SelectData.mobileSelect2 : "-1"
  );
  const [mobileSelect3, setMobileSelect3] = useState(
    isEditing ? SelectData.mobileSelect3 : "-1"
  );
  const [optionsMobileSelect2, setOptionsMobileSelect2] = useState<string[]>(
    []
  );
  const [optionsMobileSelect3, setOptionsMobileSelect3] = useState<string[]>(
    []
  );
  const [isSamwinCheckboxChecked, setIsSamwinCheckboxChecked] = useState(
    isEditing ? SelectData.isSamwinCheckboxChecked : false
  );
  const [samwinSelect1, setSamwinSelect1] = useState(
    isEditing ? SelectData.samwinSelect1 : "-1"
  );
  const [samwinSelect2, setSamwinSelect2] = useState(
    isEditing ? SelectData.samwinSelect2 : "-1"
  );
  const [samwinSelect3, setSamwinSelect3] = useState(
    isEditing ? SelectData.samwinSelect3 : "-1"
  );
  const [optionsSamwinSelect2, setOptionsSamwinSelect2] = useState<string[]>(
    []
  );
  const [optionsSamwinSelect3, setOptionsSamwinSelect3] = useState<string[]>(
    []
  );

  useEffect(() => {
    if (isEditing && SelectData) {
      setGroupName(SelectData.groupName);
      setIsCheckboxChecked(SelectData.isCheckboxChecked);
      setIsMobilePresenceCB(SelectData.isMobilePresenceCB);
      setIsTeamsPresenceCB(SelectData.isTeamsPresenceCB);
      setIsSamwinCheckboxChecked(SelectData.isSamwinCheckboxChecked);
      setMobileSelect1(SelectData.mobileSelect1);
      setMobileSelect2(SelectData.mobileSelect2);
      setMobileSelect3(SelectData.mobileSelect3);
      setSamwinSelect1(SelectData.samwinSelect1);
      setSamwinSelect2(SelectData.samwinSelect2);
      setSamwinSelect3(SelectData.samwinSelect3);
    }
  }, [isEditing, SelectData, tableId]);

  useEffect(() => {
    if (mobileSelect1 === "1") {
      setOptionsMobileSelect2(["Available"]);
      setOptionsMobileSelect3(["Available"]);
      setMobileSelect2("Available");
      setMobileSelect3("Available");
    } else if (mobileSelect1 === "2") {
      setOptionsMobileSelect2(["Busy", "Away", "DoNotDisturb"]);
    } else {
      setOptionsMobileSelect2([]);
    }
  }, [mobileSelect1]);

  useEffect(() => {
    if (mobileSelect2 === "Available") {
      setOptionsMobileSelect3(["Available"]);
    } else if (mobileSelect2 === "Busy") {
      setOptionsMobileSelect3(["InACall", "InAConferenceCall"]);
    } else if (mobileSelect2 === "Away") {
      setOptionsMobileSelect3(["Away"]);
      setMobileSelect3("Away");
    } else if (mobileSelect2 === "DoNotDisturb") {
      setOptionsMobileSelect3(["Presenting"]);
      setMobileSelect3("Presenting");
    } else {
      setOptionsMobileSelect3([]);
    }
  }, [mobileSelect2]);

  useEffect(() => {
    if (samwinSelect1 === "1") {
      setOptionsSamwinSelect2(["Available"]);
      setOptionsSamwinSelect3(["Available"]);
      setSamwinSelect2("Available");
      setSamwinSelect3("Available");
    } else if (samwinSelect1 === "2") {
      setOptionsSamwinSelect2(["Busy", "Away", "DoNotDisturb"]);
    } else {
      setOptionsSamwinSelect2([]);
    }
  }, [samwinSelect1]);

  useEffect(() => {
    if (samwinSelect2 === "Available") {
      setOptionsSamwinSelect3(["Available"]);
    } else if (samwinSelect2 === "Busy") {
      setOptionsSamwinSelect3(["InACall", "InAConferenceCall"]);
    } else if (samwinSelect2 === "Away") {
      setOptionsSamwinSelect3(["Away"]);
      setSamwinSelect3("Away");
    } else if (samwinSelect2 === "DoNotDisturb") {
      setOptionsSamwinSelect3(["Presenting"]);
      setSamwinSelect3("Presenting");
    } else {
      setOptionsSamwinSelect3([]);
    }
  }, [samwinSelect2]);

  const handleMPCheckboxChange = (event: any) => {
    setIsMobilePresenceCB(event.target.checked);
  };

  const handleTPCheckboxChange = (event: any) => {
    setIsTeamsPresenceCB(event.target.checked);
  };

  const handleCheckboxChange = (event: any) => {
    setIsCheckboxChecked(event.target.checked);
    setMobileSelect1("-1");
    setMobileSelect2("-1");
    setMobileSelect3("-1");
  };

  const handleSamwinCheckboxChange = (event: any) => {
    setIsSamwinCheckboxChecked(event.target.checked);
    setSamwinSelect1("-1");
    setSamwinSelect2("-1");
    setSamwinSelect3("-1");
  };

  const handleMobileSelect1Change = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setMobileSelect1(event.target.value);
    setMobileSelect2("-1");
    setMobileSelect3("-1");
  };

  const handleMobileSelect2Change = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setMobileSelect2(event.target.value);
    setMobileSelect3("-1");
  };

  const handleMobileSelect3Change = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setMobileSelect3(event.target.value);
  };

  const handleSamwinSelect1Change = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSamwinSelect1(event.target.value);
    setSamwinSelect2("-1");
    setSamwinSelect3("-1");
  };

  const handleSamwinSelect2Change = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSamwinSelect2(event.target.value);
    setSamwinSelect3("-1");
  };

  const handleSamwinSelect3Change = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSamwinSelect3(event.target.value);
  };

  return (
    <div className="d-flex flex-md-row flex-column m-4 align-items-start justify-content-center">
      <div className="d-flex flex-row flex-md-column pb-3 w-100">
        <label htmlFor="groupName">Group name:</label>
        <input
          className="form-control form-control-md text-primary"
          type="text"
          id="groupName"
          name="groupName"
          placeholder="Group name"
          value={groupName}
          onChange={(event) => setGroupName(event.target.value)}
          disabled={isEditing}
        />
      </div>
      <div className="d-flex flex-column border border-1 rounded border-primary w-100 p-4 ms-md-4 ms-0">
        <div className="d-flex flex-column flex-md-row">
          <div className="d-flex flex-row form-check ms-md-4 mb-2">
            <input
              className="form-check-input me-2"
              type="checkbox"
              id="mobilepresenceCB"
              name="mobilepresenceCB"
              checked={isMobilePresenceCB}
              onChange={handleMPCheckboxChange}
            />
            <label htmlFor="mobilepresenceCB">Mobile Presence</label>
          </div>
          <div className="d-flex flex-row form-check ms-md-4 mb-2">
            <input
              className="form-check-input me-2"
              type="checkbox"
              id="teamspresenceCB"
              name="teamspresenceCB"
              checked={isTeamsPresenceCB}
              onChange={handleTPCheckboxChange}
            />
            <label htmlFor="teamspresenceCB">Teams Presence</label>
          </div>
        </div>
        <div className="flex-column flex-md-row form-check ms-md-4 mb-2">
          <input
            className="form-check-input"
            type="checkbox"
            id="isCheckboxChecked"
            name="isCheckboxChecked"
            checked={isCheckboxChecked}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="isCheckboxChecked">Mobile Presence in Teams</label>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-center w-100">
          <div className="d-flex flex-column m-2">
            <label htmlFor="mobileSelect1"> Mobile Status:</label>
            <select
              className="form-select form-select-sm text-primary groups-input-size"
              id="mobileSelect1"
              name="mobileSelect1"
              value={mobileSelect1}
              disabled={!isCheckboxChecked}
              onChange={handleMobileSelect1Change}
            >
              <option value="-1">Select</option>
              <option value="1">Available</option>
              <option value="2">Busy</option>
            </select>
          </div>
          <div className="d-flex flex-column m-2">
            <label htmlFor="mobileSelect2">Mobile Availability:</label>
            <select
              className="form-select form-select-sm text-primary groups-input-size"
              id="mobileSelect2"
              name="mobileSelect2"
              value={mobileSelect2}
              disabled={!isCheckboxChecked}
              onChange={handleMobileSelect2Change}
            >
              <option value="-1">Select</option>
              {optionsMobileSelect2.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className="d-flex flex-column m-2">
            <label htmlFor="mobileSelect3">Mobile Activity:</label>
            <select
              className="form-select form-select-sm text-primary groups-input-size"
              id="mobileSelect3"
              name="mobileSelect3"
              value={mobileSelect3}
              disabled={!isCheckboxChecked}
              onChange={handleMobileSelect3Change}
            >
              <option value="-1">Select</option>
              {optionsMobileSelect3.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex-column flex-md-row form-check ms-md-4 mb-2 mt-3">
          <input
            className="form-check-input"
            type="checkbox"
            id="isSamwinCheckboxChecked"
            name="isSamwinCheckboxChecked"
            checked={isSamwinCheckboxChecked}
            onChange={handleSamwinCheckboxChange}
          />
          <label htmlFor="isSamwinCheckboxChecked">
            Samwin Presence in Teams
          </label>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-center w-100">
          <div className="d-flex flex-column m-2">
            <label htmlFor="samwinSelect1"> Samwin Status:</label>
            <select
              className="form-select form-select-sm text-primary groups-input-size"
              id="samwinSelect1"
              name="samwinSelect1"
              value={samwinSelect1}
              disabled={!isSamwinCheckboxChecked}
              onChange={handleSamwinSelect1Change}
            >
              <option value="-1">Select</option>
              <option value="1">Available</option>
              <option value="2">Busy</option>
            </select>
          </div>
          <div className="d-flex flex-column m-2">
            <label htmlFor="samwinSelect2">Samwin Availability:</label>
            <select
              className="form-select form-select-sm text-primary groups-input-size"
              id="samwinSelect2"
              name="samwinSelect2"
              value={samwinSelect2}
              disabled={!isSamwinCheckboxChecked}
              onChange={handleSamwinSelect2Change}
            >
              <option value="-1">Select</option>
              {optionsSamwinSelect2.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className="d-flex flex-column m-2">
            <label htmlFor="samwinSelect3">Samwin Activity:</label>
            <select
              className="form-select form-select-sm text-primary groups-input-size"
              id="samwinSelect3"
              name="samwinSelect3"
              value={samwinSelect3}
              disabled={!isSamwinCheckboxChecked}
              onChange={handleSamwinSelect3Change}
            >
              <option value="-1">Select</option>
              {optionsSamwinSelect3.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectForm;
