import React from "react";

export interface SpinnerProps {
  className?: string;
  style?: React.CSSProperties;
}

const Spinner: React.FC<SpinnerProps> = (props: SpinnerProps) => {
  return (
    <div
      className={
        "spinner-border" + (props.className ? " " + props.className : "")
      }
      style={props.style}
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};

export default Spinner;
