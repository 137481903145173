import React from "react";

export interface CardBodyProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const CardBody: React.FC<CardBodyProps> = (props: CardBodyProps) => {
  return (
    <div
      className={"card-body" + (props.className ? " " + props.className : "")}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

export default CardBody;
