import CardBody from "./ui/CardBody";
import Card from "./ui/Card";
import CustomerBar from "./CustomerBar";
import "./SamwinRecording.css";
import Mp3Player from "./Mp3Player";
import { Desktop, TabletMobile } from "../utils/breakpoints";

const SamwinRecording: React.FC = () => {
  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar name="Voice memo" />
      </div>
      <Card className="border border-primary shadow rounded">
        <CardBody className="border border-primary border-start-0 border-end-0 border-bottom-0">
          {/* mp3 player */}
          {/* > 768px */}
          <Desktop>
            <div className="d-flex flex-xl-row  flex-column justify-content-center pb-4">
              <div className="d-flex flex-column p-2">
                <Mp3Player id="1" file="./sound/samplememo.mp3" />
              </div>
              <div className="d-flex flex-column p-2">
                <Mp3Player id="2" file="./sound/samplememo.mp3" />
              </div>
            </div>
            <div className="d-flex flex-xl-row flex-column justify-content-center pb-4 ">
              <div className="d-flex flex-column p-2">
                <Mp3Player id="3" file="./sound/samplememo.mp3" />
              </div>
              <div className="d-flex flex-column p-2">
                <Mp3Player id="4" file="./sound/samplememo.mp3" />
              </div>
            </div>
          </Desktop>

          {/*< 576px */}
          <TabletMobile>
            <div className="d-flex flex-xl-row  flex-column justify-content-center pb-4">
              <div className="d-flex flex-column p-2">
                <Mp3Player id="1" file="./sound/samplememo.mp3" />
              </div>
              <div className="d-flex flex-column p-2">
                <Mp3Player id="2" file="./sound/samplememo.mp3" />
              </div>
            </div>
            <div className="d-flex flex-xl-row flex-column justify-content-center pb-4 ">
              <div className="d-flex flex-column p-2">
                <Mp3Player id="3" file="./sound/samplememo.mp3" />
              </div>
              <div className="d-flex flex-column p-2">
                <Mp3Player id="4" file="./sound/samplememo.mp3" />
              </div>
            </div>
          </TabletMobile>
        </CardBody>
      </Card>
    </>
  );
};

export default SamwinRecording;
