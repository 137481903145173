import React from "react";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import "./SideNavLink.css";

export interface SideNavLinkProps {
  to?: string;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  image?: string;
  sidebarOpen?: boolean;
  tooltip?: string;
}

const SideNavLink: React.FC<SideNavLinkProps> = (props: SideNavLinkProps) => {
  return (
    <li className="side-nav-link">
      <Link
        className={"d-block " + props.className}
        style={{ textDecoration: "none" }}
        to={props.to ?? ""}>
        <div className="side-nav-link-container d-flex flex-row align-items-center ps-2">
          <Icon
            className="side-nav-link-icon me-2"
            fill="white"
            name={props.image}
          />
          <span>{props.children}</span>
          {props.tooltip && !props.sidebarOpen && (
            <span className="tooltip-text">{props.tooltip}</span>
          )}
          {props.tooltip && props.sidebarOpen && (
            <span className="tooltip-text-open">{props.tooltip}</span>
          )}
        </div>
      </Link>
    </li>
  );
};

export default SideNavLink;
