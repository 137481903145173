import React from "react";

export interface DropDownToggleProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const DropDownToggle: React.FC<DropDownToggleProps> = (
  props: DropDownToggleProps
) => {
  let className =
    "btn dropdown-toggle" + (props.className ? " " + props.className : "");
  return (
    <button
      className={className}
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default DropDownToggle;
