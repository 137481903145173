import React from "react";
import "./HoverMenu.css";

export interface HoverMenuProps {
  children?: React.ReactNode;
  name?: string;
  className?: string;
  style?: React.CSSProperties;
}

const HoverMenu: React.FC<HoverMenuProps> = (props: HoverMenuProps) => {
  return (
    <div
      className={
        "hover-menu d-flex flex-menu flex-column ps-0" +
        (props.className ? " " + props.className : "")
      }
      style={props.style}>
      <small className="d-flex w-100 ps-3 hover-menu-name">{props.name}</small>
      <ul className="ps-0 hover-menu-link" style={{ listStyle: "none" }}>
        {props.children}
      </ul>
    </div>
  );
};

export default HoverMenu;
