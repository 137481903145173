import React from "react";

export interface CardHeaderProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const CardHeader: React.FC<CardHeaderProps> = (props: CardHeaderProps) => {
  return (
    <div
      className={"card-header" + (props.className ? " " + props.className : "")}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

export default CardHeader;
