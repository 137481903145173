import React, { useState, useEffect, useContext, ReactElement } from "react";
import CustomerBar from "./CustomerBar";
import { ApplicationContext } from "../context/ApplicationContext";
import Table, { TableData, TableInfo } from "./ui/Table";
import GroupApplicationMock from "./GroupApplicationMock";
import CardBody from "./ui/CardBody";
import Card from "./ui/Card";
import CardFooter from "./ui/CardFooter";
import "./GroupApplication.css";

const GroupApplication: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);

  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });
  const [data, setData] = useState<TableData>(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [frmError, setFrmError] = useState(false);
  const [input, setInput] = useState<{ [key: string]: string }>({});
  const [addCollapse, setAddCollapse] = useState(false);
  const [customerId, setCustomerId] = useState<number>(
    applicationContext.webApi?.isSuperAdmin()
      ? applicationContext.customerId
      : applicationContext.webApi?.getCredentials()?.customer ?? -1
  );

  const clearSearch = () => {
    setTableInfo({ ...tableInfo, search: "" });
  };

  useEffect(() => {
    setCustomerId(applicationContext.customerId);
    setData(GroupApplicationMock);
    setIsLoading(false);
    clearSearch();
  }, [applicationContext.customerId]);

  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = parseInt(event.target.value, 10);
    setCustomerId(id);
    console.log(customerId);
    clearSearch();
  };

  const handleClose = (index: number) => {
    let tempData = { ...data };
    tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
    setData(tempData);
  };

  const handleAddClose = () => {
    setAddCollapse(!addCollapse);
  };

  const handleDeleteYes = (index: number) => {
    index >= 0 ? handleClose(index) : handleAddClose();
    setDeleteConfirm(false);
  };

  const handleDeleteNo = () => {
    setDeleteConfirm(false);
  };

  const handleDelete = () => {
    setDeleteConfirm(true);
  };

  const submitEditHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (event.currentTarget.reportValidity()) {
      console.log("added");
    } else {
      setFrmError(true);
    }
  };

  const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const collapseArea = (index: number) => {
    return (
      <form
        className={"needs-validation" + (frmError ? " was-validated" : "")}
        onSubmit={(event) => {
          submitEditHandler(event);
        }}
        noValidate>
        <Card className="bg-secondary bg-opacity-25 m-2">
          <CardBody>
            <div className="d-flex flex-column align-items-center mb-2">
              <div className="d-flex flex-column flex-md-row align-items-left w-100 pe-2 mb-3">
                <div className="d-flex flex-row pe-3 ">
                  <label
                    className="form-label text-primary ps-md-3 groupApp-label-size"
                    htmlFor={`applicationName`}
                    id={`applicationName`}>
                    Name:
                  </label>
                </div>
                <div className="d-flex flex-row w-md-50 text-primary groupApp-input">
                  <input
                    className="form-control text-primary ps-md-3 groupApp-label-size"
                    id={`applicationName`}
                    name={index >= 0 ? "applicationName" : "appliationNameAdd"}
                    value={input["appliationName"]}
                    onChange={handleInputOnChange}
                    required
                  />
                </div>
              </div>
            </div>
          </CardBody>

          <CardFooter className="bg-secondary bg-opacity-25">
            <div className="d-flex flex-row justify-content-between">
              <div>
                <div className={index >= 0 ? "" : "d-none"}>
                  <div className={`${deleteConfirm ? "" : "d-none"}`}>
                    <span className="text-primary me-2">
                      Are you sure you want to remove?
                    </span>
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary me-3"
                      onClick={() => {
                        handleDeleteYes(index);
                      }}>
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary"
                      onClick={handleDeleteNo}>
                      No
                    </button>
                  </div>
                  <div style={deleteConfirm ? { display: "none" } : {}}>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger groupApp-edit-button me-2"
                      onClick={handleDelete}>
                      Remove
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="me-1 me-md-3 mb-2 mb-md-0">
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary groupApp-edit-button"
                    onClick={() =>
                      index >= 0 ? handleClose(index) : handleAddClose()
                    }>
                    Close
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-primary groupApp-edit-button"
                    type="submit">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </CardFooter>
        </Card>
      </form>
    );
  };

  const renderForm = (index: number) => {
    return (
      <form
        className={"needs-validation" + (frmError ? " was-validated" : "")}
        onSubmit={(event) => {
          submitEditHandler(event);
        }}
        noValidate>
        <Card className="bg-secondary bg-opacity-25 m-2">
          <CardBody>
            <div className="d-flex flex-column align-items-center mb-2">
              <div className="d-flex flex-column flex-md-row align-items-left w-100 pe-2 mb-3">
                <div className="d-flex flex-row pe-3">
                  <label
                    className="form-label text-primary ps-md-3 groupApp-label-size"
                    htmlFor={`id`}
                    id={`id-label`}>
                    Id:
                  </label>
                </div>
                <div className="d-flex flex-row w-md-50 groupApp-input">
                  <input
                    className="form-control text-primary"
                    name={index >= 0 ? "id" : " idAdd"}
                    id={`id-${index}`}
                    onChange={handleInputOnChange}
                    required
                  />
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-left w-100 pe-2 mb-3">
                <div className="d-flex flex-row pe-3">
                  <label
                    className="form-label text-primary ps-md-3 groupApp-label-size"
                    htmlFor={`applicationName`}>
                    Name:
                  </label>
                </div>
                <div className="d-flex flex-row w-md-50 groupApp-input">
                  <input
                    className="form-control text-primary"
                    id={`applicationName-${index}`}
                    name={index >= 0 ? "applicationName" : "appliationNameAdd"}
                    onChange={handleInputOnChange}
                  />
                </div>
              </div>
            </div>
          </CardBody>
          <CardFooter className="bg-secondary bg-opacity-25">
            <div className="d-flex flex-row justify-content-between">
              <div>
                <div className={index >= 0 ? "" : "d-none"}>
                  <div className={`${deleteConfirm ? "" : "d-none"}`}>
                    <span className="text-primary me-2">
                      Are you sure you want to remove?
                    </span>
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary me-3"
                      onClick={() => {
                        handleDeleteYes(index);
                      }}>
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary"
                      onClick={handleDeleteNo}>
                      No
                    </button>
                  </div>
                  <div style={deleteConfirm ? { display: "none" } : {}}>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger groupApp-edit-button me-2"
                      onClick={handleDelete}>
                      Remove
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="me-1 me-md-3 mb-2 mb-md-0">
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary groupApp-edit-button"
                    onClick={() =>
                      index >= 0 ? handleClose(index) : handleAddClose()
                    }>
                    Close
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-primary groupApp-edit-button"
                    type="submit">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </CardFooter>
        </Card>
      </form>
    );
  };

  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar
          name="Application"
          onCustomerChange={handleCustomerChange}
        />
      </div>
      <div>
        <Table
          className="table table-sm"
          data={data}
          tableInfo={tableInfo} 
        setTableInfo={setTableInfo}
          responsive
          bordered
          isLoading={isLoading}
          addCollapse={addCollapse}
          toggleAddCollapse={(): void => {
            let inputData = { ...input };
            inputData["idAdd"] = "";
            inputData["applicationNameAdd"] = "";
            setInput(inputData);
            setAddCollapse(!addCollapse);
          }}
          toggleEditCollapse={(index: number): void => {
            let tempData = { ...data };
            let inputData = { ...input };
            tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
            inputData["id"] = tempData.rows[index].id;
            inputData["applciationName"] = tempData.rows[index].applciationName;
            setInput(inputData);
            setData(tempData);
          }}
          renderAddCollapse={(): ReactElement => {
            return renderForm(-1);
          }}
          renderEditCollapse={(index: number): ReactElement => {
            return collapseArea(index);
          }}
        />
      </div>
    </>
  );
};

export default GroupApplication;
