import React from "react";

export interface PaginationProps {
  ariaLabel: string;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
  return (
    <nav aria-label={props.ariaLabel}>
      <ul
        className={
          "pagination" + (props.className ? " " + props.className : "")
        }
        style={props.style}
      >
        {props.children}
      </ul>
    </nav>
  );
};

export default Pagination;
