import React from "react";
import { ReactComponent as Dashboard } from "../icons/Dashboard.svg";
import { ReactComponent as Presence } from "../icons/Presence.svg";
import { ReactComponent as AlectooApp } from "../icons/AlectooApp.svg";
import { ReactComponent as License } from "../icons/License.svg";
import { ReactComponent as Users } from "../icons/UsersGroup.svg";
import { ReactComponent as Customers } from "../icons/Customers.svg";
import { ReactComponent as PhoneNumbers } from "../icons/PhoneNumbers.svg";
import { ReactComponent as ChevronDown } from "../icons/ChevronDown.svg";
import { ReactComponent as ChevronUp } from "../icons/ChevronUp.svg";
import { ReactComponent as Phonemanager } from "../icons/PhoneManager.svg";
import { ReactComponent as Gear } from "../icons/Gear.svg";
import { ReactComponent as HouseGear } from "../icons/HouseGear.svg";
import { ReactComponent as Speedometer } from "../icons/Speedometer.svg";
import { ReactComponent as Telephone } from "../icons/Telephone.svg";
import { ReactComponent as Integration } from "../icons/Integration.svg";
import { ReactComponent as AlectooServices } from "../icons/AlectooServices.svg";
import { ReactComponent as DeleteIcon } from "../icons/Delete.svg";
import { ReactComponent as GroupSettings } from "../icons/GroupSettings.svg";
import { ReactComponent as Groups } from "../icons/Groups.svg";
import { ReactComponent as GroupUser } from "../icons/GroupUser.svg";
import { ReactComponent as Providers } from "../icons/Hand.svg";
import { ReactComponent as Aggregation } from "../icons/Aggregation.svg";
import { ReactComponent as AppPage } from "../icons/App.svg";
import { ReactComponent as CSVIcon } from "../icons/CSVIcon.svg";
import { ReactComponent as MonitoringIcon } from "../icons/Monitoring.svg";
import { ReactComponent as SamwinManager } from "../icons/SamwinManager.svg";
import { ReactComponent as Manager } from "../icons/Manager.svg";
import { ReactComponent as Recordings } from "../icons/Recordings.svg";
import { ReactComponent as HamburgerMenu } from "../icons/icons8-hamburger.svg";
import { ReactComponent as CloseX } from "../icons/closeX.svg";

export interface IconProps {
  className?: string;
  style?: React.CSSProperties;
  fill?: string;
  name?: string;
}

const Icon: React.FC<IconProps> = (props: IconProps) => {
  let tempIcon: React.JSX.Element = <></>;

  switch (props.name) {
    case "dashboard":
      tempIcon = (
        <Dashboard
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "phonenumbers":
      tempIcon = (
        <PhoneNumbers
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "presence":
      tempIcon = (
        <Presence
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "alectooapp":
      tempIcon = (
        <AlectooApp
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "integraton":
      tempIcon = (
        <Integration
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "users":
      tempIcon = (
        <Users
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "license":
      tempIcon = (
        <License
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "customers":
      tempIcon = (
        <Customers
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "chevrondown":
      tempIcon = (
        <ChevronDown
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "chevronup":
      tempIcon = (
        <ChevronUp
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "phonemanager":
      tempIcon = (
        <Phonemanager
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "gear":
      tempIcon = (
        <Gear
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "housegear":
      tempIcon = (
        <HouseGear
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "speedometer":
      tempIcon = (
        <Speedometer
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "telephone":
      tempIcon = (
        <Telephone
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "services":
      tempIcon = (
        <AlectooServices
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "delete_icon":
      tempIcon = (
        <DeleteIcon
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "groupsettings":
      tempIcon = (
        <GroupSettings
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "groups":
      tempIcon = (
        <Groups
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "groupuser":
      tempIcon = (
        <GroupUser
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "providers":
      tempIcon = (
        <Providers
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "aggregation":
      tempIcon = (
        <Aggregation
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "application":
      tempIcon = (
        <AppPage
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "csvicon":
      tempIcon = (
        <CSVIcon
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "monitoring":
      tempIcon = (
        <MonitoringIcon
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "samwinManager":
      tempIcon = (
        <SamwinManager
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "manager":
      tempIcon = (
        <Manager
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "recordings":
      tempIcon = (
        <Recordings
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "hamburgerMenu":
      tempIcon = (
        <HamburgerMenu
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "closeX":
      tempIcon = (
        <CloseX
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    default:
      break;
  }

  return tempIcon;
};

export default Icon;
