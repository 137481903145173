import React from "react";

export interface PaginationLinkProps {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
}

const PaginationLink: React.FC<PaginationLinkProps> = (
  props: PaginationLinkProps
) => {
  return (
    <button className="page-link" onClick={props.onClick}>
      {props.children}
    </button>
  );
};

export default PaginationLink;
