import React, { useEffect, useState } from "react";
import "./SelectSearch.css";
export interface Option {
  value: string | number;
  label: string;
}

const SelectWithSearch: React.FC<{
  options: Option[];
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  onSelect: (option: Option) => void;
}> = ({ options, onSelect, searchTerm, setSearchTerm }) => {
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    if (options) {
        const tempOptions = options.filter((option) => {
            return option.label.toLowerCase().includes(searchTerm.toLowerCase());
        });
        setFilteredOptions(tempOptions);
    } else {
        setFilteredOptions([]);
    }
}, [options, searchTerm]);

  const handleOptionClick = (option: Option) => {
    onSelect(option); 
    setSearchTerm("");
  };


  return (
    <div className="dropdown">
      <button
        type="button"
        className="form-select text-primary rounded rounded-1 form-select-width"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-auto-close="outside"
      >
        Search and select users
      </button>
      <div className="dropdown-menu form-group pt-0">
        <div className="p-2 sticky-top">
          <input
            type="text"
            className="form-control text-primary select-input"
            data-toggle="dropdown"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <ul className="list-unstyled">
      {filteredOptions.map((option) => (
        <li
          key={`option-${option.value}}`}
          className="dropdown-item"
          onClick={() => handleOptionClick(option)}
          onKeyDown={() => handleOptionClick(option)}
        >
          {option.label}
        </li>
      ))}
    </ul>
      </div>
    </div>
  );
};

export default SelectWithSearch;
