import React, {
  ReactElement,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import CustomerBar from "./CustomerBar";
import { ApplicationContext } from "../context/ApplicationContext";
import Table, { TableData, TableInfo } from "./ui/Table";
import CardBody from "./ui/CardBody";
import Card from "./ui/Card";
import CardFooter from "./ui/CardFooter";
import ReactFlagsSelect from "react-flags-select";
import { customLabels } from "../helpers/countryCodeLabels";

import "./PhoneRange.css";

const PhoneRange: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TableData>(initialData);
  const [addCollapse, setAddCollapse] = useState(false);
  const [input, setInput] = useState<{ [key: string]: string }>({});
  const [customerId, setCustomerId] = useState<number>(
    applicationContext.customerId
  );
  const [frmError, setFrmError] = useState(false);
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const loadDataForCustomer = useCallback(
    (customerId: number, currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      applicationContext.webApi
        ?.getPhoneRanges(customerId, currentPage, step, search)
        .then((response) => {
          setIsLoading(false);
          setData(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    },
    [applicationContext.webApi]
  );

  const clearSearch = () => {
    setTableInfo({ ...tableInfo, search: "" });
  };

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setTableInfo({ currentPage: currentPage, step: step, search: search });
      loadDataForCustomer(customerId, currentPage, step, search);
    },
    [customerId, loadDataForCustomer]
  );

  useEffect(() => {
    loadDataForCustomer(customerId, 1, tableInfo.step, tableInfo.search);
  }, [customerId]);

  useEffect(() => {
    setCustomerId(applicationContext.customerId);
    clearSearch();
  }, [applicationContext.customerId]);

  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = parseInt(event.target.value, 10);
    setCustomerId(id);
    clearSearch();
  };

  const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleClose = (index: number) => {
    let tempData = Object.assign({}, data);
    tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
    setData(tempData);
  };

  const handleAddClose = () => {
    setAddCollapse(!addCollapse);
  };

  const submitEditHandler = (
    event: React.FormEvent<HTMLFormElement>,
    index: number
  ) => {
    event.preventDefault();
    if (event.currentTarget.reportValidity()) {
      if (index >= 0) {
        handleEditOnSave(index);
        setFrmError(false);
      } else {
        handleAdd();
      }
    } else {
      setFrmError(true);
    }
  };

  const handleEditOnSave = (index: number) => {
    const country = input["country"];
    const site = input["site"];
    const numberFrom = input["numberFrom"];
    const numberTo = input["numberTo"];
    const areaZip = input["areaZip"];
    const address = input["address"];

    applicationContext.webApi
      ?.savePhoneRange({
        id: data.rows[index].id,
        customerId: customerId,
        country: country,
        site: site,
        numberFrom: numberFrom,
        numberTo: numberTo,
        areaZip: areaZip,
        address: address,
      })
      .then(() => {
        setTimeout(() => {
          loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
        }, 500);
      });
  };

  const handleAdd = () => {
    const country = input["country"];
    const site = input["siteAdd"];
    const numberFrom = input["numberFromAdd"];
    const numberTo = input["numberToAdd"];
    const areaZip = input["areaZipAdd"];
    const address = input["addressAdd"];

    applicationContext.webApi
      ?.createPhoneRange({
        id: 0,
        customerId: customerId,
        country: country,
        site: site,
        numberFrom: numberFrom,
        numberTo: numberTo,
        areaZip: areaZip,
        address: address,
      })
      .then(() => {
        handleAddClose();
        setTimeout(() => {
          loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
        }, 500);
      });
  };

  const handleDeleteYes = (index: number) => {
    const phoneRangeId = data.rows[index].id;
    index >= 0 ? handleClose(index) : handleAddClose();
    applicationContext.webApi?.deletePhoneRange(phoneRangeId).then(() => {
      setTimeout(() => {
        loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
      }, 500);
      setDeleteConfirm(false);
    });
  };

  const handleDeleteNo = () => {
    setDeleteConfirm(false);
  };
  const handleDelete = () => {
    setDeleteConfirm(true);
  };

  const isNumberPlus = (event:any) => {
    let keyCode = event.key.charCodeAt(0);
    let shiftKey = event.shiftKey;
    if (
      (keyCode > 47 && keyCode < 58) ||
      (keyCode > 64 && keyCode < 69) || (keyCode === 43 && shiftKey === true)
    ) {
      return true;
    }

    event.preventDefault();
}

  const phoneRangeForm = (index: number) => {
    return (
      <form
        className={"needs-validation" + (frmError ? " was-validated" : "")}
        onSubmit={(event) => {
          submitEditHandler(event, index);
        }}
        noValidate>
        <div>
        <Card className="bg-secondary bg-opacity-25 m-2">
          <CardBody>
            {/* 1 */}
            <div className="d-flex flex-column flex-md-row align-items-center mb-2">
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2">
                <label
                  className="form-label text-primary mb-0 ps-md-2 phone-range-label-size"
                  htmlFor={`country-${index}`}
                  id={`country-label-${index}`}>
                  Country:
                </label>
                <ReactFlagsSelect
                  className="w-100 text-primary"
                  id={`country-${index}`}
                  selected={input["country"] || ""}
                  onSelect={(code) => {
                    let tempInput = Object.assign({}, input);
                    tempInput = Object.assign(tempInput, {
                      ["country"]: code,
                    });
                    setInput(tempInput);
                  }}
                  fullWidth={true}
                  selectedSize={15}
                  searchable={true}
                  customLabels={customLabels}
                  showOptionLabel={true}
                  showSecondaryOptionLabel={true}
                  showSecondarySelectedLabel={true}
                  showSelectedLabel={true}
                  selectButtonClassName="phone-range-select-flag bg-white text-primary w-100"
                  />
              </div>
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative">
                <label
                  className="form-label text-primary ps-md-3 mb-0 phone-range-label-size"
                  htmlFor={`site-${index}`}
                  id={`site-label-${index}`}>
                  Site:
                </label>
                <input
                  className="form-control text-primary"
                  placeholder="Enter a city..."
                  name={index >= 0 ? "site" : "siteAdd"}
                  id={`site-${index}`}
                  value={
                    index >= 0 ? input["site"] || "" : input["siteAdd"] || ""
                  }
                  onChange={handleInputOnChange}
                required/>
                 <div className="invalid-tooltip">
                  Site is required!
                </div>
              </div>
            </div>
            {/* 2 */}
            <div className="d-flex flex-column flex-md-row align-items-center mb-md-2">
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative">
                <label
                  className="form-label text-primary ps-md-2 phone-range-label-size"
                  htmlFor={`numberFrom-${index}`}
                  id={`numberFrom-label-${index}`}>
                  Number from:
                </label>
                <input
                  className="form-control text-primary"
                  name={index >= 0 ? "numberFrom" : "numberFromAdd"}
                  id={`numberFrom-${index}`}
                  value={
                    index >= 0
                      ? input["numberFrom"] || ""
                      : input["numberFromAdd"] || ""
                  }
                  onChange={handleInputOnChange}
                  onKeyDown={isNumberPlus}
                  maxLength={16}
                  required
                />
                <div className="invalid-tooltip">
                  Number From is required!
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative">
                <label
                  className="text-primary form-label ps-md-3 mb-0 phone-range-label-size"
                  htmlFor={`numberTo-${index}`}
                  id={`numberTo-label-${index}`}>
                  Number to:
                </label>
                <input
                  className="form-control text-primary"
                  name={index >= 0 ? "numberTo" : "numberToAdd"}
                  id={`numberTo-${index}`}
                  value={
                    index >= 0
                      ? input["numberTo"] || ""
                      : input["numberToAdd"] || ""
                  }
                  onChange={handleInputOnChange}
                  onKeyDown={isNumberPlus}
                  maxLength={16}
                  required
                />
                    <div className="invalid-tooltip">
                    Number To is required!
                    </div>
              </div>
            </div>
            {/* 3 */}
            <div className="d-flex flex-column flex-md-row align-items-center mb-2">
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2">
                <label
                  className="text-primary form-label mb-0 ps-md-2 phone-range-label-size"
                  htmlFor={`areaZip-${index}`}
                  id={`areaZip-label-${index}`}>
                  Area/Zip:
                </label>
                <input
                  className="form-control text-primary"
                  name={index >= 0 ? "areaZip" : "areaZipAdd"}
                  id={`areaZip-${index}`}
                  value={
                    index >= 0
                      ? input["areaZip"] || ""
                      : input["areaZipAdd"] || ""
                  }
                  onChange={handleInputOnChange}
                />
              </div>
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2">
                <label
                  className="form-label text-primary ps-md-3 mb-0 phone-range-label-size"
                  htmlFor={`address-${index}`}
                  id={`address-label-${index}`}>
                  Address:
                </label>
                <input
                  className="form-control text-primary"
                  name={index >= 0 ? "address" : "addressAdd"}
                  id={`address-${index}`}
                  value={
                    index >= 0
                      ? input["address"] || ""
                      : input["addressAdd"] || ""
                  }
                  onChange={handleInputOnChange}
                />
              </div>
            </div>
          </CardBody>
          <CardFooter className="bg-secondary bg-opacity-25">
          <div className="d-flex flex-row justify-content-between">
            <div>
              <div className={index >= 0 ? "" : "d-none"}>
                <div className={`${deleteConfirm ? "" : "d-none"}`}>
                  <span className="text-primary me-2">
                    Are you sure you want to delete Phone Range?
                  </span>
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary me-3"
                    onClick={() => {
                      handleDeleteYes(index);
                    }}>
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={handleDeleteNo}>
                    No
                  </button>
                </div>
                <div style={deleteConfirm ? { display: "none" } : {}}>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger phone-range-edit-button"
                    onClick={handleDelete}>
                    Delete
                  </button>
                </div>
              </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="me-1 me-md-3 mb-2 mb-md-0">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary phone-range-edit-button"
                  onClick={() =>
                    index >= 0 ? handleClose(index) : handleAddClose()
                  }>
                  Close
                </button>
                </div>
                <div>
                <button
                  className="btn btn-sm btn-primary phone-range-edit-button"
                  type="submit">
                  Save
                </button>
                </div>
              </div>
            </div>
          </CardFooter>
        </Card>
        </div>
      </form>
    );
  };

  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar
          name="Phone Range"
          onCustomerChange={handleCustomerChange}></CustomerBar>
      </div>

      <Table
        className="table table-sm"
        data={data}
        tableInfo={tableInfo} 
        setTableInfo={setTableInfo}
        responsive
        bordered
        loadData={loadData}
        addCollapse={addCollapse}
        isLoading={isLoading}
        toggleAddCollapse={(): void => {
          let inputData = Object.assign({}, input);
          inputData["country"] = "";
          inputData["siteAdd"] = "";
          inputData["numberFromAdd"] = "";
          inputData["numberToAdd"] = "";
          inputData["areaZipAdd"] = "";
          inputData["addressAdd"] = "";

          setAddCollapse(!addCollapse);
          setInput(inputData);
        }}
        toggleEditCollapse={(index: number): void => {
          let tempData = Object.assign({}, data);
          let inputData = Object.assign({}, input);
          tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
          inputData["country"] = tempData.rows[index].country;
          inputData["site"] = tempData.rows[index].site;
          inputData["numberFrom"] = tempData.rows[index].numberFrom;
          inputData["numberTo"] = tempData.rows[index].numberTo;
          inputData["areaZip"] = tempData.rows[index].areaZip;
          inputData["address"] = tempData.rows[index].address;

          setInput(inputData);
          setData(tempData);
        }}
        renderEditCollapse={(index: number): ReactElement => {
          return phoneRangeForm(index);
        }}
        renderAddCollapse={(): ReactElement => {
          return phoneRangeForm(-1);
        }}></Table>
    </>
  );
};

export default PhoneRange;
