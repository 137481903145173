import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../context/ApplicationContext";

export interface CustomerViewBarProps {
  name?: string;
  buttonText?: string;
  className?: string;
  children?: string;
}

const CustomerViewBar: React.FC<CustomerViewBarProps> = () => {
  const applicationContext = useContext(ApplicationContext);
  const customerId = applicationContext.customerId;
  const setCustomerId = applicationContext.setCustomerId;
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    applicationContext.webApi
      ?.getCustomers(0, 0, "", false)
      .then((response) => {
        setCustomers(response.rows);
      })
      .catch((error) => {
        console.error("Error fetching customers:", error);
      });
  }, [applicationContext.webApi]);

  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedCustomerId = parseInt(event.target.value);
    setCustomerId(selectedCustomerId);
  };

  const renderCustomers = () => {
    return (
      <>
        <select
          className="form-select me-3 text-primary bg-white"
          onChange={handleCustomerChange}
          value={customerId}
        >
          {customers
            .slice()
            .sort((a: any, b: any) => a.name.localeCompare(b.name))
            .map((customer: any) => (
              <option key={customer.id} value={customer.id}>
                {customer.name}
              </option>
            ))}
        </select>
      </>
    );
  };

  return (
    <div className="d-flex d-row align-items-center">
      <div className="d-none d-md-block d text-white fs-5 pe-3">Customers</div>
      <div className="d-sm-shrink">{renderCustomers()}</div>
    </div>
  );
};

export default CustomerViewBar;
