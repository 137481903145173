import React, {
  useContext,
  useState,
  ReactElement,
  FormEvent,
  useEffect,
  useCallback,
} from "react";
import CustomerBar from "./CustomerBar";
import CardBody from "./ui/CardBody";
import CardHeader from "./ui/CardHeader";
import Card from "./ui/Card";
import Table, { TableData, TableInfo } from "./ui/Table";
import DropDownSelect, { Option } from "./ui/DropDownSelect";
import { ApplicationContext } from "../context/ApplicationContext";
import { PhoneNumber } from "../services/web-api/application-api";
import Collapse from "./ui/Collapse";
import CardFooter from "./ui/CardFooter";
import Icon from "./ui/Icon";
import ReactFlagsSelect from "react-flags-select";
import { customLabels } from "../helpers/countryCodeLabels";

import "./PhoneNumbers.css";

const PhoneNumbers: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };

  const statusOptions: Option[] = [
    { label: "Free", value: "0" },
    { label: "Busy", value: "1" },
    { label: "No Number", value: "2" },
    { label: "Special", value: "3" },
    { label: "Call Queue", value: "4" },
    { label: "Attendant", value: "5" },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TableData>(initialData);
  const [showFilter, setShowFilter] = useState(false);
  const [frmError, setFrmError] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[] | null>(null);
  const [status, setStatus] = useState<Option[] | undefined>([]);
  const [country, setCountry] = useState("");
  const [filterApply, setFilterApply] = useState(false);
  const [markConfirm, setMarkConfirm] = useState(false);
  const [markSpecial, setMarkSpecial] = useState(false);
  const [customerId, setCustomerId] = useState<number>(
    applicationContext.customerId
  );
  const [input, setInput] = useState<{ [key: string]: string }>({});
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });

  const loadDataForCustomer = useCallback(
    (
      selectedCustomerId: number,
      currentPage: number,
      step: number,
      search: string
    ) => {
      setIsLoading(true);
      applicationContext.webApi
        ?.getTelephoneNumbers(
          selectedCustomerId,
          currentPage,
          step,
          search,
          "",
          "",
          ""
        )
        .then((response) => {
          setIsLoading(false);
          setData(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    },
    [applicationContext.webApi]
  );

  const clearSearch = () => {
    setTableInfo({ ...tableInfo, search: "" });
  };

  useEffect(() => {
    loadDataForCustomer(customerId, 1, tableInfo.step, tableInfo.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setFilterApply(false);
  }, [customerId, loadDataForCustomer]);

  useEffect(() => {
    setCustomerId(applicationContext.customerId);
    clearSearch();
  }, [applicationContext.customerId]);

  useEffect(() => {
    loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterApply]);

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      setTableInfo({ currentPage: currentPage, step: step, search: search });

      let sendStatus = "";
      let site = "";
      if (filterApply) {
        site = input.site || "";
        status?.forEach((item: Option) => {
          sendStatus += item.value + ",";
        });
        if (sendStatus.length > 0) {
          sendStatus = sendStatus.substring(0, sendStatus.length - 1);
        }
      }

      applicationContext.webApi
        ?.getTelephoneNumbers(
          customerId,
          currentPage,
          step,
          search,
          filterApply ? country : "",
          filterApply ? sendStatus : "",
          site
        )
        .then((response) => {
          setData(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          applicationContext.webApi
            ?.getPhoneNumbers(customerId)
            .then((number) => {
              setPhoneNumbers(number);
              setIsLoading(false);
            })
            .catch((error) => {
              console.log(error);
              setIsLoading(false);
            });
        });
    },
    [filterApply, customerId, applicationContext.webApi]
  );

  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = parseInt(event.target.value, 10);
    setCustomerId(id);
    clearSearch();
  };

  const handleClose = (index: number) => {
    let tempData = Object.assign({}, data);
    tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
    setData(tempData);
  };

  const submitEditHandler = (
    event: FormEvent<HTMLFormElement>,
    index: number
  ) => {
    event.preventDefault();
    if (event.currentTarget.reportValidity()) {
      handleEditOnSave(index);
      setFrmError(false);
    } else {
      setFrmError(true);
    }
  };

  const submitFilterHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (event.currentTarget.reportValidity()) {
      setFrmError(false);
    } else {
      setFrmError(true);
      console.log(frmError);
    }
  };

  const handleEditOnSave = (index: number) => {
    let comment = input["comment_" + index];
    let special_str = input["special_" + index];
    let special: boolean = special_str === "true" ? true : false;

    let phoneNumber: any = phoneNumbers?.find(
      (phone) =>
        (data.rows[index].number.length > 0 &&
          phone.number === data.rows[index].number) ||
        (data.rows[index].endpoint.length > 0 &&
          phone.endpoint === data.rows[index].endpoint)
    );

    if (phoneNumber) {
      phoneNumber.comment = comment;
      phoneNumber.special = special ? 1 : 0;
    } else {
      phoneNumber = {
        id: 0,
        customerId: customerId * 1,
        number: data.rows[index].number ? data.rows[index].number : "",
        endpoint: data.rows[index].endpoint ? data.rows[index].endpoint : "",
        comment: comment,
        special: special ? 1 : 0,
        displayName: data.rows[index].displayName
          ? data.rows[index].displayName
          : "",
      };
    }

    if (applicationContext.webApi?.upsertPhoneNumber(phoneNumber)) {
      handleClose(index);
      setTimeout(() => {
        loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
      }, 500);
    }
  };

  const handleInputOnChange = (event: any) => {
    const value = !event.target.id.includes("special")
      ? event.target.value
      : event.target.checked;
    let tempInput = Object.assign({}, input);
    tempInput = Object.assign(tempInput, { [event.target.id]: `${value}` });
    if (
      !event.target.id.includes("comment") &&
      !event.target.id.includes("special")
    ) {
      setFilterApply(false);
    }
    setInput(tempInput);
  };

  const toggleRowEdit = (index: number) => {
    let tempData = Object.assign({}, data);
    let inputData = Object.assign({}, input);
    tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;

    inputData["comment_" + index] = tempData.rows[index].comment;
    inputData["special_" + index] =
      tempData.rows[index].status === 3 ? "true" : "false";

    setInput(inputData);
    setData(tempData);
  };

  const rowCustomRender = (i: number, j: number) => {
    let row = data.rows[i];
    let column = data.columns[j];

    if (column.field === "status") {
      if (row.status === 1) {
        return (
          <div className="d-flex justify-content-center">
            <Icon className="phone-numbers-icon" fill="red" name="telephone" />
          </div>
        );
      } else if (row.status === 2) {
        return (
          <div className="d-flex justify-content-center">
            <Icon
              className="phone-numbers-icon"
              fill="purple"
              name="telephone"
            />
          </div>
        );
      } else if (row.status === 3) {
        return (
          <div className="d-flex justify-content-center">
            <Icon
              className="phone-numbers-icon"
              fill="yellow"
              name="telephone"
            />
          </div>
        );
      } else {
        return (
          <div className="d-flex justify-content-center">
            <Icon
              className="phone-numbers-icon"
              fill="green"
              name="telephone"
            />
          </div>
        );
      }
    } else if (column.field === "enterpriseVoice") {
      return row[column.field] === true ? "Yes" : "No";
    } else {
      return row[column.field] === null ? "" : row[column.field].toString();
    }
  };

  const collapseForm = () => {
    return (
      <Card className="p-0 border-0">
        <CardBody className="border border-primary border-start-0 border-end-0">
          <form
            className={"needs-validation" + (frmError ? " was-validated" : "")}
            onSubmit={(event) => {
              submitFilterHandler(event);
            }}
            noValidate
          >
            <div className="d-flex flex-column flex-md-row align-items-center mb-md-2">
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-md-4">
                <label
                  className="labelForm text-primary mb-0"
                  htmlFor="country"
                >
                  Country:
                </label>
                <ReactFlagsSelect
                  className="w-100 text-primary"
                  id="country"
                  selected={country}
                  onSelect={(code) => {
                    setFilterApply(false);
                    setCountry(code);
                  }}
                  fullWidth={true}
                  selectedSize={15}
                  searchable={true}
                  customLabels={customLabels}
                  showOptionLabel={true}
                  showSecondaryOptionLabel={true}
                  showSecondarySelectedLabel={true}
                  showSelectedLabel={true}
                  selectButtonClassName="phone-numbers-select-button bg-white text-primary w-100"
                />
              </div>
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100">
                <label className="labelForm text-primary ps-md-3 mb-0">
                  Site:
                </label>
                <input
                  className="form-control text-primary"
                  placeholder="Enter site name..."
                  id="site"
                  type="text"
                  value={input["site"] || ""}
                  onChange={handleInputOnChange}
                />
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row align-items-center mb-md-2">
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-md-4">
                <label className="labelForm text-primary" htmlFor="status">
                  Status:
                </label>
                <DropDownSelect
                  placeHolder="Select Status"
                  className="phone-numbers-select-button text-primary"
                  multiple={true}
                  options={statusOptions}
                  onChange={(status) => {
                    setFilterApply(false);
                    setStatus(status);
                  }}
                  selectedOption={status}
                  selectedSize={15}
                />
              </div>
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2">
                <span className="invalid-tooltip">Site is required!</span>
              </div>
            </div>
          </form>
        </CardBody>
        <CardFooter className="bg-white pb-0">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
            <div className="d-flex flex-column flex-md-row flex-wrap align-items-center">
              <div className={`${markConfirm ? "mb-2" : "d-none"}`}>
                <div className={`${markSpecial ? "mb-md-2" : "d-none"}`}>
                  <textarea
                    className="form-control form-control-sm text-primary"
                    id="comment_mark"
                    name="comment_mark"
                    value={input["comment_mark"] || ""}
                    onChange={handleInputOnChange}
                    rows={2}
                    cols={6}
                    placeholder="Comment"
                  />
                </div>
                <div className={`${markSpecial ? 6 : 12}`}>
                  <span className="mt-2 me-2 text-black-50">
                    {markSpecial
                      ? "Are you sure you want to mark special?"
                      : "Are you sure you want to unmark special?"}
                  </span>
                  <button
                    className="btn btn-sm btn-danger m-2"
                    onClick={() => {
                      let sendStatus = "";
                      let site = "";
                      let comment = markSpecial ? input.comment_mark || "" : "";
                      // Perform other actions as needed
                      if (filterApply) {
                        site = input.site || "";
                        status?.forEach((item) => {
                          sendStatus += item.value + ",";
                        });

                        if (sendStatus.length > 0) {
                          sendStatus = sendStatus.substring(
                            0,
                            sendStatus.length - 1
                          );
                        }
                      }

                      applicationContext.webApi
                        ?.savePhoneNumberSpecial({
                          customerId: customerId,
                          special: markSpecial ? "1" : "0",
                          page: tableInfo.currentPage,
                          limit: tableInfo.step,
                          search: tableInfo.search,
                          country: filterApply ? country : "",
                          status: sendStatus,
                          site: site,
                          comment: comment,
                        })
                        .then(() => {
                          loadData(
                            tableInfo.currentPage,
                            tableInfo.step,
                            tableInfo.search
                          );
                        });

                      setMarkConfirm(false);
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-sm btn-primary me-2"
                    onClick={() => {
                      setMarkConfirm(false);
                      input.comment_mark = "";
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
              <div style={markConfirm ? { display: "none" } : {}}>
                <button
                  className="btn btn-sm btn-secondary phone-numbers-buttons me-md-2 m-1 mb-md-0"
                  onClick={() => {
                    setMarkConfirm(true);
                    setMarkSpecial(true);
                  }}
                >
                  Mark Special
                </button>
                <button
                  className="btn btn-sm btn-secondary phone-numbers-buttons m-1 mb-md-0"
                  onClick={() => {
                    setMarkConfirm(true);
                    setMarkSpecial(false);
                  }}
                >
                  Unmark Special
                </button>
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
              <div>
                <button
                  className="btn btn-sm btn-secondary phone-numbers-buttons me-md-2 m-1 mb-md-0"
                  type="reset"
                  onClick={() => {
                    let tempInput = Object.assign({}, input);
                    tempInput["site"] = "";
                    setInput(tempInput);
                    setCountry("");
                    setStatus([]);
                    setFilterApply(false);
                  }}
                >
                  Reset
                </button>
                <button
                  className="btn btn-sm btn-primary phone-numbers-buttons m-1 mb-md-0"
                  type="submit"
                  onClick={() => {
                    setFilterApply(true);
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    );
  };

  const collapseTextArea = (index: number) => {
    return (
      <form
        className={"needs-validation" + (frmError ? " was-validated" : "")}
        onSubmit={(event) => {
          submitEditHandler(event, index);
        }}
        noValidate
      >
        <Card className="border-0 shadow m-3">
          <CardBody className="bg-secondary bg-opacity-50 text-primary p-0 m-0">
            <div className="d-flex d-flex-row m-4">
              <label className="labelComment">Comment:</label>
              <textarea
                id={"comment_" + index}
                name={"comment_" + index}
                value={input["comment_" + index] || ""}
                rows={parseInt("5")}
                cols={parseInt("40")}
                placeholder="Add comment here ..."
                className="form-control form-control-sm w-50 texarea text-primary"
                onChange={handleInputOnChange}
              ></textarea>
            </div>
            <div className="d-flex d-flex-row m-4 align-items-center">
              <label className="labelCommentSpecial me-2 justify-content-left">
                Special:
              </label>
              <div className="form-check form-switch">
                <input
                  type="checkbox"
                  role="switch"
                  className="form-check-input bg-primary bg-opacity-50 border-primary"
                  id={"special_" + index}
                  name={"special_" + index}
                  checked={input["special_" + index] === "true" ? true : false}
                  onChange={handleInputOnChange}
                ></input>
                <label className="labelCommentSpecial">
                  &nbsp; Yellow phone icon
                </label>
              </div>
            </div>
            <CardFooter className="mt-2 pt-2 border border-primary border-start-0 border-end-0 border-bottom-0">
              <div className="d-flex d-flex-row justify-content-end">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm phone-numbers-filter-button m-2"
                  onClick={() => {
                    handleClose(index);
                  }}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm phone-numbers-filter-button m-2"
                >
                  Save
                </button>
              </div>
            </CardFooter>
          </CardBody>
        </Card>
      </form>
    );
  };

  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar
          name="Phone Numbers"
          onCustomerChange={handleCustomerChange}
        ></CustomerBar>
      </div>
      <Card className="rounded-4 border-primary mb-0">
        <CardHeader className="bg-transparent border border-primary border-start-0 border-end-0 border-top-0 p-0">
          <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-3">
            <div className="phoneNumber-bar-font">Mobile Application Info</div>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn btn-sm btn-primary phone-numbers-filter-button mt-2 me-2"
                onClick={() => {}}
              >
                Download CSV
                <Icon
                  name="csvicon"
                  className="ms-1"
                  style={{ fill: "white" }}
                />
              </button>
              <button
                type="button"
                className="btn btn-sm btn-primary phone-numbers-filter-button mt-2"
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              >
                Filter
              </button>
            </div>
          </div>
          <Collapse isExpanded={showFilter}>{collapseForm()}</Collapse>
        </CardHeader>
        <CardBody>
          <Table
            className="table table-sm"
            data={data}
            tableInfo={tableInfo}
            setTableInfo={setTableInfo}
            responsive
            bordered
            loadData={loadData}
            rowCustomRender={rowCustomRender}
            isLoading={isLoading}
            toggleEditCollapse={(index): void => {
              return toggleRowEdit(index);
            }}
            renderEditCollapse={(index: number): ReactElement => {
              return collapseTextArea(index);
            }}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default PhoneNumbers;
