import React, {
    Dispatch,
    FormEvent,
    useState,
    useContext,
    SetStateAction
  } from "react";

  import { ApplicationContext } from "../context/ApplicationContext";
  import { LoginState } from "./Login";
  
  interface IFrogotPasswordMsgProps {
    email: string;
    setLoginState?: Dispatch<SetStateAction<LoginState>>;
  }
  const ForgotPasswordMsg: React.FC<IFrogotPasswordMsgProps> = ({ email, setLoginState }) => {

  const applicationContext = useContext(ApplicationContext);
  const [formError, setFormError] = useState<boolean>(false);
  const [messageText, setMsgText] = useState<string>("");

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      console.log(event);
      setMsgText("");
      if (event.currentTarget.reportValidity()) {
        applicationContext.webApi
         ?.forgotPassword(email)
        .then((response: any) => {
         console.log(response);
         let messageText = "Your e-mail has been resent."
         setMsgText(messageText);
          })
        .catch((error: any) => {
          console.log(error);
        });
      } else {
        setFormError(true);
      }
    };
  
    return (
      <>
        <img
          src="./images/logo_login.png"
          className="img-logo mb-5"
          alt="Alectoo brand logo"></img>
          
          <h2 className="text-primary mb-4">Please check your e-mail</h2>
          <div className="container-text">
          <p className="text-info mb-2">{messageText}</p>
          <p className="text-primary mb-5 text-break">You are almost there. We've sent an e-mail to <b>{email}</b>.</p>
          <p className="text-primary text-break">Just click on the link we provided to complete the password reset.</p>
          <p className="text-primary mb-5 text-break">If you don't see the e-mail, please check your <b>spam</b> folder.</p>
          </div>
       
        <label className="text-primary mb-1">Still can't find the e-mail?</label>
        <form
        className={
          "needs-validation" + (formError === true ? " was-validated" : "")
        }
        onSubmit={handleSubmit}
        noValidate
        >
          <button type="submit" className="btn btn-primary w-100 mb-4"
          >
            Resend e-mail
          </button>
          <div className="text-primary h5">
          <a
                  role="button"
                  onClick={() => {
                    if (setLoginState) setLoginState("login");
                  }}
                > <b>Back to Login</b>
            </a>
          </div>
        </form>
      </>
    );
  };

  export default ForgotPasswordMsg;