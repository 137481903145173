import React, { useState, useContext, useEffect, useCallback } from "react";
import CustomerBar from "./CustomerBar";
import Table, { TableData, TableInfo } from "./ui/Table";
import { ApplicationContext } from "../context/ApplicationContext";

const Presence: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TableData>(initialData);
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });
  const [customerId, setCustomerId] = useState<number>(
    applicationContext.customerId
  );

  const clearSearch = () => {
    setTableInfo({ ...tableInfo, search: "" });
  };

  const loadDataForCustomer = useCallback(
    (customerId: number, currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      applicationContext.webApi
        ?.getPresence(customerId, currentPage, step, search)
        .then((response) => {
          setIsLoading(false);
          setData(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    },
    [applicationContext.webApi]
  );

  useEffect(() => {
    loadDataForCustomer(customerId, 1, tableInfo.step, tableInfo.search);
  
  }, [customerId]);

  useEffect(() => {
    setCustomerId(applicationContext.customerId);
    clearSearch();
  }, [applicationContext.customerId]);

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setTableInfo({ currentPage: currentPage, step: step, search: search });
      loadDataForCustomer(customerId, currentPage, step, search);
    },
    [customerId, loadDataForCustomer]
  );

  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = parseInt(event.target.value, 10);
    setCustomerId(id);
    clearSearch();
  };

  const rowCustomRender = (i: number, j: number) => {
    let row = data.rows[i];
    let column = data.columns[j];

    if (column.field === "state") {
      if (row[column.field] === 1) {
        return "Avaliable";
      } else if (row[column.field] === 2) {
        return "Busy";
      } else {
        return "Offline";
      }
    } else if (column.field === "showPresence") {
      return row[column.field] === true ? "Yes" : "No";
    } else if (column.field === "showTeams") {
      return row[column.field] === true ? "Yes" : "No";
    } else {
      return row[column.field] === null ? "" : row[column.field].toString();
    }
  };

  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar name="Presence" onCustomerChange={handleCustomerChange} />
      </div>
      <div>
        <Table
          tableInfo={tableInfo} 
          setTableInfo={setTableInfo}
          className="table table-sm"
          data={data}
          responsive
          bordered
          rowCustomRender={rowCustomRender}
          loadData={loadData}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default Presence;
