const GroupApplicationMock = {
  total: 1,
  page: 1,
  limit: 10,
  columns: [
    {
      label: "ID",
      field: "id",
      width: "50%",
    },
    {
      label: "Name",
      field: "applicationName",
      width: "50%",
    },
  ],
  rows: [
    {
      id: "WT",
      applicationName: "651dcaa97b2bfbf50bcc3df0",
    },
    {
      id: "LO",
      applicationName: "651dcacdedc29a6a7bb83377",
    },
    {
      id: "TM",
      applicationName: "651dcada2aa65af564adb318",
    },
    {
      id: "W3",
      applicationName: "651dcaecfd45fe8bf4359b1e",
    },
    {
      id: "RR",
      applicationName: "651dcaf47de28a76fed5c125",
    },
    {
      id: "I9",
      applicationName: "651dcafa06355c8abe1e43bc",
    },
    {
      id: "S7",
      applicationName: "651dcb02b4e6564c2dfb227a",
    },
    {
      id: "GQ",
      applicationName: "651dcb08d913b3417b7ba688",
    },
    {
      id: "DP",
      applicationName: "651dcb0d063e996a3bed3a64",
    },
    {
      id: "FI",
      applicationName: "651dcb18dce96d588dee109e",
    },
  ],
};
export default GroupApplicationMock;
