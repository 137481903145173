import React, { useEffect, useState, useContext } from "react";
import { ApplicationContext } from "../context/ApplicationContext";
import { CustomerResponse } from "../services/web-api/application-api";
import "./CustomerBar.css";

export interface CustomerBarProps {
  name: string;
  buttonText?: string;
  onCustomerChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const CustomerBar: React.FC<CustomerBarProps> = (props: CustomerBarProps) => {
  const applicationContext = useContext(ApplicationContext);
  const [customerId, setCustomerId] = useState<number>(
    applicationContext.webApi?.getCredentials()?.customer ?? -1
  );
  const [customers, setCustomers] = useState<CustomerResponse[]>([]);

  const handleBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onButtonClick?.(event);
  };

  useEffect(() => {
    setCustomerId(applicationContext.customerId);
    applicationContext.webApi
      ?.getCustomersOf(applicationContext.customerId)
      .then((response: CustomerResponse[]) => {
        setCustomers(response);
      })
      .catch((error) => {
        console.error("Error fetching customers:", error);
      });
  }, [applicationContext.webApi, applicationContext.customerId]);

  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedCustomerId = parseInt(event.target.value);
    setCustomerId(selectedCustomerId);
    props.onCustomerChange?.(event);
  };

  const renderCustomers = () => {
    if (applicationContext.webApi?.isUser()) return null;

    return (
      <>
        <div className="d-flex flex-row d-none d-md-block text-primary customer-bar-font-h3 pe-2">
          Customers
        </div>
        <div className="d-sm-shrink">
          <select
            className="form-select me-3 text-primary bg-light "
            onChange={handleCustomerChange}
            value={customerId}
          >
            {customers
              .slice()
              .sort((a: any, b: any) => a.name.localeCompare(b.name))
              .map((customer) => (
                <option key={customer.id} value={customer.id}>
                  {customer.name}
                </option>
              ))}
          </select>
        </div>
      </>
    );
  };

  return (
    <div className="d-flex flex-row border rounded-4 border-primary shadow justify-content-between align-items-center p-3">
      <div className="pe-1 text-primary customer-bar-font">{props.name}</div>
      <div className="d-flex flex-row">
        {props.onButtonClick && (
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleBtnClick}
          >
            {props.buttonText}
          </button>
        )}
      </div>
      <div className="ps-2 d-flex flex-row align-items-center">
        {props.onCustomerChange ? renderCustomers() : null}
      </div>
    </div>
  );
};

export default CustomerBar;
