import React from "react";
import CustomerBar from "./CustomerBar";

const SamwinManager: React.FC = () => {
  return (
    <div className="mb-3 mt-3 pl-3">
      <CustomerBar name="Samwin Manager" />
    </div>
  );
};

export default SamwinManager;
