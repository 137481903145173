import {
  CustomerResponse,
  IApplicationApi,
  LoginResponse,
  PhoneNumber,
  PhoneRange,
  User,
  Customer,
  PhoneNumberSpecial,
  Monitoring,
} from "../application-api";
import jwt_decode, { JwtPayload } from "jwt-decode";

const handleResponse = async (response: Response) => {
  let data: any;
  if (!response.ok) {
    const status = response.status;
    return Promise.reject(status);
  } else {
    try {
      const json = response.json && (await response.json());
      data = json.data ? json.data : json;
    } catch (error) {
      data = null;
    }

    return data;
  }
};

export class ApplicationRestApi implements IApplicationApi {
  private credentials: LoginResponse | null = null;
  private userRole: string;

  constructor(private url: string) {
    let localResponse = localStorage.getItem("loginResponse");
    this.userRole = "";
    if (localResponse) {
      this.credentials = JSON.parse(localResponse) as LoginResponse;
    }
    if (this.credentials?.token) {
      this.parseToken(this.credentials.token);
    }
  }

  isSuperAdmin(): boolean {
    return this.userRole === "SuperAdmin";
  }
  isAdmin(): boolean {
    return this.userRole === "Admin" || this.userRole === "Partner";
  }
  isPartner(): boolean {
    return this.userRole === "Partner";
  }
  isUser(): boolean {
    return this.userRole === "Member";
  }

  getCredentials(): LoginResponse | null {
    return this.credentials;
  }

  private parseToken(jwtToken: string) {
    let token = jwt_decode<JwtPayload>(jwtToken);
    let date = new Date();
    let now = (date.getTime() / 1000) | 0; //truncate
    let expiresIn = token?.exp && (token.exp ? token.exp - now : 0);

    if (
      token as any["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
    ) {
      this.userRole = (token as { [key: string]: string })[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    }

    if (expiresIn && expiresIn > 0) {
      expiresIn *= 1000;
      setTimeout(() => {
        //logout after token expires
        this.credentials = null;
        window.location.reload();
      }, expiresIn);
    } else {
      this.credentials = null;
    }
  }

  //login to Alectoo cloud service
  async login(
    username: string,
    password: string,
    code: string,
    rememberMe: boolean
  ): Promise<LoginResponse> {
    return new Promise<LoginResponse>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
        body: JSON.stringify({ username, password, code, rememberMe }),
      };

      fetch(`${this.url}/api/authorization/login`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          this.credentials = response;
          this.parseToken(response.token);
          localStorage.setItem("loginResponse", JSON.stringify(response));
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async forgotPassword(email: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(
        `${this.url}/api/authorization/resetpassword/?userName=${email}`,
        requestOptions
      )
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async changePassword(token: string, password: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, password }),
      };

      fetch(`${this.url}/api/authorization/changepassword/`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getCustomers(
    page: number,
    limit: number,
    search: string,
    includeCustomers: boolean
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      search = encodeURIComponent(search);

      const url =
        page === 0 && limit === 0
          ? `${this.url}/api/data/customers`
          : `${this.url}/api/data/customers?page=${page}&limit=${limit}&search=${search}&includeCustomers=${includeCustomers}`;

      fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getPresence(
    customerId: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      search = encodeURIComponent(search);

      fetch(
        `${this.url}/api/data/userpresence?customerId=${customerId}&page=${page}&limit=${limit}&search=${search}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async logout(): Promise<void> {
    return new Promise<void>((resolve) => {
      localStorage.removeItem("loginResponse");
      this.credentials = null;
      this.userRole = "";
      resolve();
    });
  }

  async getCustomersOf(customerId: number): Promise<CustomerResponse[]> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(`${this.url}/api/data/customers/${customerId}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getUsers(
    customerId: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      search = encodeURIComponent(search);

      fetch(
        `${this.url}/api/data/users?customerId=${customerId}&page=${page}&limit=${limit}&search=${search}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getMobileUsers(
    customerId: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      search = encodeURIComponent(search);

      fetch(
        `${this.url}/api/data/mobileusers?customerId=${customerId}&page=${page}&limit=${limit}&search=${search}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getPhoneNumbers(customerId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(
        `${this.url}/api/data/phoneNumbers?=customerId=${customerId}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getTelephoneNumbers(
    customerId: number,
    page: number,
    limit: number,
    search: string,
    country: string,
    status: string,
    site: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      search = encodeURIComponent(search);

      fetch(
        `${this.url}/api/data/telephonenumbers?customerId=${customerId}&country=${country}&page=${page}&limit=${limit}&search=${search}&status=${status}&site=${site}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getHuntGroups(
    customerId: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      search = encodeURIComponent(search);

      fetch(
        `${this.url}/api/data/huntgroups?customerId=${customerId}&page=${page}&limit=${limit}&search=${search}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getGroup(groupId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(`${this.url}/api/data/group?groupId=${groupId}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getGroups(
    customerId: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      search = encodeURIComponent(search);

      fetch(
        `${this.url}/api/data/ubiguitygroups?customerId=${customerId}&page=${page}&limit=${limit}&search=${search}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getGroupUsers(
    customerId: number,
    groupId: string,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      search = encodeURIComponent(search);

      fetch(
        `${this.url}/api/data/ubiguitygroupusers?customerId=${customerId}&groupid=${groupId}&page=${page}&limit=${limit}&search=${search}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getLicenses(customerId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(
        `${this.url}/api/data/licenses?customerId=${customerId}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getUsersInGroup(groupId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(
        `${this.url}/api/data/usersingroup?groupId=${groupId}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async saveGroup(group: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
        body: JSON.stringify(group),
      };

      fetch(`${this.url}/api/data/ubiquitygroup`, requestOptions)
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async updateUserIsOnline(
    groupId: number,
    userId: number,
    checked: boolean
  ): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(
        `${this.url}/api/data/UpdateUserIsOnline/${groupId}/?userId=${userId}&isOnline=${checked}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          console.log(response);
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          reject(false);
        });
    });
  }

  async getPhoneRanges(
    customerId: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      search = encodeURIComponent(search);

      fetch(
        `${this.url}/api/data/phonerange?customerId=${customerId}&page=${page}&limit=${limit}&search=${search}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getCustomerIntegration(customerId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(`${this.url}/api/data/customer/${customerId}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getIntegrationUser(customerId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(
        `${this.url}/api/data/integrationuser?customerId=${customerId}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async saveIntegrationUser(
    customerId: number,
    userName: string,
    password: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
        body: JSON.stringify({ userName, password, customerId }),
      };

      fetch(`${this.url}/api/data/integrationuser`, requestOptions)
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getSecret(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(`${this.url}/api/data/secret`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async createSecret(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(`${this.url}/api/data/createsecret`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async deleteSecret(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(`${this.url}/api/data/secret`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async updateSecret(secret: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + this.credentials?.token,
        },
        body: "secret=" + secret,
      };

      fetch(`${this.url}/api/data/secret`, requestOptions)
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async verifyCode(secret: string, code: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + this.credentials?.token,
        },
        body: "secret=" + secret + "&code=" + code,
      };

      fetch(`${this.url}/api/data/verifycode`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getPhoneNumbersInfo(customerId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(
        `${this.url}/api/data/phonenumbersinfo?customerId=${customerId}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async upsertPhoneNumber(phoneNumber: PhoneNumber): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
        body: JSON.stringify(phoneNumber),
      };

      fetch(`${this.url}/api/data/phonenumbers`, requestOptions)
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async saveUser(user: User): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
        body: JSON.stringify(user),
      };

      fetch(`${this.url}/api/data/users/${user.id}`, requestOptions)
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async createUser(user: User): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
        body: JSON.stringify(user),
      };

      fetch(`${this.url}/api/data/users`, requestOptions)
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async deleteUser(userId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(`${this.url}/api/data/users/${userId}`, requestOptions)
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async savePhoneRange(phoneRange: PhoneRange): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
        body: JSON.stringify(phoneRange),
      };

      fetch(`${this.url}/api/data/phonerange/${phoneRange.id}`, requestOptions)
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async createPhoneRange(phoneRange: PhoneRange): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
        body: JSON.stringify(phoneRange),
      };

      fetch(`${this.url}/api/data/phonerange`, requestOptions)
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async deletePhoneRange(phoneRangeId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(`${this.url}/api/data/phonerange/${phoneRangeId}`, requestOptions)
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async saveCustomer(customer: Customer): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
        body: JSON.stringify(customer),
      };

      fetch(`${this.url}/api/data/customers/${customer.id}`, requestOptions)
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async createCustomer(customer: Customer): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
        body: JSON.stringify(customer),
      };

      fetch(`${this.url}/api/data/customers`, requestOptions)
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async deleteCustomer(customerId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(`${this.url}/api/data/customers/${customerId}`, requestOptions)
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async savePhoneNumberSpecial(
    phoneNumberSpecial: PhoneNumberSpecial
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(
        `${this.url}/api/data/phonenumbersspecial?customerId=` +
          phoneNumberSpecial.customerId +
          "&special=" +
          phoneNumberSpecial.special +
          "&country=" +
          phoneNumberSpecial.country +
          "&page=" +
          phoneNumberSpecial.page +
          "&limit=" +
          phoneNumberSpecial.limit +
          "&search=" +
          encodeURIComponent(phoneNumberSpecial.search) +
          "&status=" +
          phoneNumberSpecial.status +
          "&site=" +
          phoneNumberSpecial.site +
          "&comment=" +
          encodeURIComponent(phoneNumberSpecial.comment),
        requestOptions
      )
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async saveDomains(customerId: number, domains: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + this.credentials?.token,
        },
        body: "domains=" + domains,
      };

      fetch(`${this.url}/api/data/domains/${customerId}`, requestOptions)
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getDomains(customerId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(`${this.url}/api/data/domains/${customerId}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  async getMonitoring(customerId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(
        `${this.url}/api/data/monitoring/?customerid=${customerId}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  async upsertMonitoring(monitoring: Monitoring): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + this.credentials?.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(monitoring),
      };

      fetch(`${this.url}/api/data/monitoring`, requestOptions)
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => reject(error));
    });
  }
}
