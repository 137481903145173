import React from "react";

export interface ConteinerProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  fluid?: boolean;
}

const Container: React.FC<ConteinerProps> = (props: ConteinerProps) => {
  let container = "container";
  if (props.fluid) {
    container = container + "-fluid";
  }

  return (
    <div
      className={container + (props.className ? " " + props.className : "")}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

export default Container;
