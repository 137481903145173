import React from "react";

export interface DropDownMenuProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const DropDownMenu: React.FC<DropDownMenuProps> = (
  props: DropDownMenuProps
) => {
  let className =
    "dropdown-menu" + (props.className ? " " + props.className : "");
  return (
    <ul className={className} style={props.style}>
      {props.children}
    </ul>
  );
};

export default DropDownMenu;
