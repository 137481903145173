import React, {
  useState,
  useContext,
  ChangeEvent,
  FormEvent,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationContext } from "../context/ApplicationContext";
import ForgotPassword from "./ForgotPassword";
import { LoginResponse } from "../services/web-api/application-api";
import Authentication from "./Authentication";

import "./Login.css";

export type LoginState = "login" | "forgotpwd" | "authenticate";

type RememberMe = {
  email: string;
  isOn: boolean;
};

const Login: React.FC = () => {
  const [loginState, setLoginState] = useState("login" as LoginState);

  const applicationContext = useContext(ApplicationContext);
  const navigate = useNavigate();
  const [input, setInput] = useState<{ [key: string]: string }>({});
  const [rememberme, setRememberme] = useState<boolean>(false);
  const [formError, setFormError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const remembermeData = localStorage.getItem("rememberme");

    if (remembermeData) {
      const tempRememberMe: RememberMe = JSON.parse(remembermeData);
      setRememberme(tempRememberMe.isOn);
      if (tempRememberMe.isOn) {
        setInput({ ...input, username: tempRememberMe.email });
      }
    }
  }, [applicationContext.webApi, navigate]);

  useEffect(() => {
    if (!input["username"] || !input["password"]) {
      setErrorMessage("");
    }
  }, [input]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleOnRememberMeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRememberme(event.target.checked);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage("");
    if (event.currentTarget.reportValidity()) {
      localStorage.setItem(
        "rememberme",
        JSON.stringify({ email: input["username"], isOn: rememberme })
      );
      applicationContext.webApi
        ?.login(input["username"], input["password"], "", rememberme)
        .then((loginResponse: LoginResponse) => {
          applicationContext.setCustomerId(loginResponse.customer);
          navigate("/");
        })
        .catch((error) => {
          let errorMessage = "Unable to login! Error: " + error;

          if (error === 409) {
            setLoginState("authenticate");
          } else if (error === 403) {
            errorMessage = "Invalid username and/or password!";
          }

          console.log(error);

          setErrorMessage(errorMessage);
        });
    } else {
      setFormError(true);
    }
  };

  const renderError = () => {
    return (
      <div className="text-danger w-100">
        <ul>
          <li>{errorMessage}</li>
        </ul>
      </div>
    );
  };

  const renderLogin = () => {
    return (
      <>
        {/* Mobile only */}
        <div className="container container-mobile d-sm-none d-flex flex-column justify-content-center">
          <img
            src="./images/logo_login.png"
            className="img-logo mb-4"
            alt="Alectoo brand logo"
          />

          {errorMessage && renderError()}

          <h2 className="text-primary mb-4">Log in</h2>
          <form
            className={
              "needs-validation" + (formError === true ? " was-validated" : "")
            }
            onSubmit={handleSubmit}
          >
            <input
              type="email"
              className="form-control mb-3"
              placeholder="Username"
              onChange={handleOnChange}
              name="username"
              value={input["username"] || ""}
              required
            />
            <span className="invalid-feedback">Username is required!</span>
            <input
              type="password"
              className="form-control mb-3"
              placeholder="Password"
              name="password"
              onChange={handleOnChange}
              value={input["password"] || ""}
              required
            />
            <span className="invalid-feedback">Password is required!</span>
            <div className="d-flex flex-column justify-content-between mb-4">
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mobile-rememberme"
                  name="rememberme"
                  onChange={handleOnRememberMeChange}
                  checked={rememberme}
                />
                <label className="form-check-label" htmlFor="mobile-rememberme">
                  Remember me
                </label>
              </div>
              <div>
                <a
                  className="text-primary underline"
                  role="button"
                  onClick={() => {
                    setLoginState("forgotpwd");
                  }}
                >
                  Forgot password?
                </a>
              </div>
            </div>
            <button type="submit" className="btn btn-primary w-100 mb-3">
              Log in
            </button>
          </form>
        </div>

        {/* Larger than mobile */}
        <div className="d-none d-sm-flex larger">
          <div className="container container-desktop d-flex container-left flex-column justify-content-center p-5">
            <img
              src="./images/logo_login.png"
              className="img-logo mb-4"
              alt="Alectoo brand logo"
            ></img>
            {errorMessage && renderError()}
            <h2 className="text-primary mb-4">Log in</h2>
            <form
              className={
                "needs-validation" +
                (formError === true ? " was-validated" : "")
              }
              onSubmit={handleSubmit}
              noValidate
            >
              <input
                type="email"
                className="form-control mb-2 email"
                placeholder="Username"
                value={input["username"] || ""}
                name="username"
                onChange={handleOnChange}
                required
              />
              <span className="invalid-feedback mb-2">
                Username is required!
              </span>
              <div className="has-validation">
                <input
                  type="password"
                  className="form-control mb-2 primary password"
                  placeholder="Password"
                  value={input["password"] || ""}
                  name="password"
                  onChange={handleOnChange}
                  required
                />
                <span className="invalid-feedback mb-2">
                  Password is required!
                </span>
              </div>
              <div className="d-flex flex-row justify-content-between mb-4 options">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="desktop-rememberme"
                    name="rememberme"
                    onChange={handleOnRememberMeChange}
                    checked={rememberme}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="desktop-rememberme"
                  >
                    Remember me
                  </label>
                </div>
                <div>
                  <a
                    className="text-primary underline"
                    role="button"
                    onClick={() => {
                      setLoginState("forgotpwd");
                    }}
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100 mt-3 mb-5 p-2"
              >
                Log in
              </button>
            </form>
          </div>
          <div className="container-right d-flex align-items-center bg-secondary">
            <img
              className="img-people p-5 "
              src="./images/Hello_edited.png"
              alt="Greeting image"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100 position-fixed">
      <div className="d-flex bg-white m-auto">
        {(loginState === "login" && renderLogin()) ||
          (loginState === "forgotpwd" && (
            <ForgotPassword setLoginState={setLoginState} />
          )) ||
          (loginState === "authenticate" && (
            <Authentication
              numberOfDigits={6}
              userName={input["username"]}
              password={input["password"]}
              rememberme={rememberme}
              setLoginState={setLoginState}
            />
          ))}
      </div>
    </div>
  );
};

export default Login;
