import React from "react";

export interface DropDownDeviderProps {
  className?: string;
  style?: React.CSSProperties;
}

const DropDownDevider: React.FC<DropDownDeviderProps> = (
  props: DropDownDeviderProps
) => {
  return (
    <li className={props.className} style={props.style}>
      <hr className="dropdown-devider" />
    </li>
  );
};

export default DropDownDevider;
