import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import CustomerBar from "./CustomerBar";
import Table, { TableData, TableInfo } from "./ui/Table";
import { ApplicationContext } from "../context/ApplicationContext";
import Card from "./ui/Card";
import CardBody from "./ui/CardBody";
import "./Groups.css";
import CardFooter from "./ui/CardFooter";
import SelectForm, { MySelectFormProps } from "./GroupTeamsForm";

const Groups: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };

  const initialSelectData = {
    groupName: "",
    isCheckboxChecked: false,
    mobileSelect1: "-1",
    mobileSelect2: "-1",
    mobileSelect3: "-1",
    isSamwinCheckboxChecked: false,
    samwinSelect1: "-1",
    samwinSelect2: "-1",
    samwinSelect3: "-1",
  };

  const initialSelectProps: MySelectFormProps = {
    isEditing: false,
    SelectData: initialSelectData,
    tableId: -1,
  };

  const [data, setData] = useState<TableData>(initialData);
  const [selectData, setSelectData] =
    useState<MySelectFormProps>(initialSelectProps);
  const [isLoading, setIsLoading] = useState(false);
  const [addCollapse, setAddCollapse] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [presenceStatus, setPresenceStatus] = useState("User Based Presence");
  const [input, setInput] = useState<{ [key: string]: string }>({});
  const [frmError, setFrmError] = useState(false);
  const [customerId, setCustomerId] = useState<number>(
    applicationContext.customerId
  );
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });

  const clearSearch = () => {
    setTableInfo({ ...tableInfo, search: "" });
  };

  const loadDataForCustomer = useCallback(
    (customerId: number, currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      applicationContext.webApi
        ?.getGroups(customerId, currentPage, step, search)
        .then((response) => {
          setIsLoading(false);
          setData(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    },
    [applicationContext.webApi]
  );

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setTableInfo({ currentPage: currentPage, step: step, search: search });
      loadDataForCustomer(customerId, currentPage, step, search);
    },
    [customerId, loadDataForCustomer]
  );

  useEffect(() => {
    loadDataForCustomer(customerId, 1, tableInfo.step, tableInfo.search);
  }, [customerId, applicationContext.webApi]);

  useEffect(() => {
    setCustomerId(applicationContext.customerId);
    clearSearch();
  }, [applicationContext.customerId]);

  //new row
  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = parseInt(event.target.value, 10);
    setCustomerId(id);
    console.log(customerId);
    console.log(data);
    clearSearch();
  };

  const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [event.target.name]: event.target.value });
    const value = event.target.checked;
    let tempInput = Object.assign({}, input);
    tempInput = Object.assign(tempInput, { [event.target.id]: `${value}` });
    if (value === true) {
      setPresenceStatus("Group Based Presence");
    } else {
      setPresenceStatus("User Based Presence");
    }
    setInput(tempInput);
  };

  const handleClose = (index: number) => {
    let tempData = { ...data };
    tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
    setData(tempData);
  };

  const handleAddClose = () => {
    setAddCollapse(!addCollapse);
  };

  const handleDeleteYes = (index: number) => {
    index >= 0 ? handleClose(index) : handleAddClose();
    // applicationContext.webApi?.deleteGroup(groupId).then(() => {
    //   setTimeout(() => {
    //     loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
    //   }, 500);
    // });
  };

  const handleDeleteNo = () => {
    setDeleteConfirm(false);
  };

  const handleDelete = () => {
    setDeleteConfirm(true);
  };

  const handleEdit = (index: number) => {
    const groupName = selectData.SelectData.groupName;
    console.log(groupName + index);
    // applicationContext.webApi
    //   ?.editGroup({
    //     id: data.rows[index].groupId,
    //     displayName: selectData.groupName,
    //     mobileToTeams: selectData.isCheckboxChecked,
    //     mobileAvailability: selectData.mobileSelect2,
    //     mobileActivity: selectData.mobileSelect3,
    //     mobileToTeams: selectData.isSamwinCheckboxChecked,
    //     mobileAvailability: selectData.samwinSelect2,
    //     mobileActivity: selectData.samwinSelect3,
    //   })
    //   .then(() => {
    //     setTimeout(() => {
    //       loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
    //     }, 500);
    //   });
  };

  const handleAdd = () => {
    const groupName = selectData.SelectData.groupName;
    console.log(groupName);
    // applicationContext.webApi
    //   ?.saveGroup({
    //     id: 0,
    //     displayName: selectData.groupName,
    //     customerId: customerId,
    //     mobileToTeams: selectData.isCheckboxChecked,
    //     mobileAvailability: selectData.mobileSelect2,
    //     mobileActivity: selectData.mobileSelect3,
    //     mobileToTeams: selectData.isSamwinCheckboxChecked,
    //     mobileAvailability: selectData.samwinSelect2,
    //     mobileActivity: selectData.samwinSelect3,
    //   })
    //   .then(() => {
    //     handleAddClose();
    //     setTimeout(() => {
    //       loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
    //     }, 500);
    //   });
  };

  const submitEditHandler = (
    event: React.FormEvent<HTMLFormElement>,
    index: number
  ) => {
    event.preventDefault();
    if (event.currentTarget.reportValidity()) {
      if (index >= 0) {
        handleEdit(index);
        setFrmError(false);
      } else {
        handleAdd();
      }
    } else {
      setFrmError(true);
    }
  };

  const sliderGroupUser = (): ReactElement => {
    return (
      <div className="d-flex flex-row align-items-center justify-content-center">
        <label className="me-2" htmlFor="presence_switch">
          <small className="text-primary"> {presenceStatus} </small>
        </label>
        <div className="form-check form-switch">
          <input
            type="checkbox"
            role="switch"
            className="form-check-input border-primary"
            id={"presence_switch"}
            name={"presence_switch"}
            checked={input["presence_switch"] === "true"}
            onChange={handleInputOnChange}
          ></input>
        </div>
      </div>
    );
  };

  const rowCustomRender = (i: number, j: number) => {
    let column = data.columns[j];
    let row: any = data.rows[i];
    if (column.field === "type") {
      let type: string = "";
      switch (row[column.field]) {
        case 0: {
          type = "Office 365";
          break;
        }
        case 1: {
          type = "Manual";
          break;
        }
        default:
          break;
      }
      return type;
    } else {
      return row[column.field] === null ? "" : row[column.field].toString();
    }
  };

  const renderForm = (index: number, isEditing: boolean, selectedData: any) => {
    return (
      <form
        className={"needs-validation p-1" + (frmError ? " was-validated" : "")}
        onSubmit={(event) => {
          submitEditHandler(event, index);
        }}
        noValidate
      >
        <Card className="border-0 shadow m-2">
          <CardBody className="bg-secondary bg-opacity-50 text-primary p-0 m-0">
            <SelectForm
              isEditing={isEditing}
              SelectData={selectedData}
              tableId={index}
            />

            <CardFooter className="bg-secondary bg-opacity-25">
              <div className="d-flex flex-row justify-content-between">
                <div>
                  <div className={index >= 0 ? "" : "d-none"}>
                    <div className={`${deleteConfirm ? "" : "d-none"}`}>
                      <span className="text-primary me-2">
                        Are you sure you want to remove?
                      </span>
                      <button
                        type="button"
                        className="btn btn-sm btn-secondary me-3"
                        onClick={() => {
                          handleDeleteYes(index);
                        }}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={handleDeleteNo}
                      >
                        No
                      </button>
                    </div>
                    <div style={deleteConfirm ? { display: "none" } : {}}>
                      <button
                        type="button"
                        className="btn btn-sm btn-danger groupApp-edit-button me-2"
                        onClick={handleDelete}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-wrap">
                  <div className="me-1 me-md-3 mb-2 mb-md-0">
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary groupApp-edit-button"
                      onClick={() =>
                        index >= 0 ? handleClose(index) : handleAddClose()
                      }
                    >
                      Close
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn btn-sm btn-primary groupApp-edit-button"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </CardFooter>
          </CardBody>
        </Card>
      </form>
    );
  };

  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar name="Groups" onCustomerChange={handleCustomerChange} />
      </div>
      <div>
        <Table
          className="table table-sm"
          data={data}
          loadData={loadData}
          tableInfo={tableInfo}
          setTableInfo={setTableInfo}
          responsive
          bordered
          isLoading={isLoading}
          addCollapse={addCollapse}
          renderHeader={sliderGroupUser}
          rowCustomRender={rowCustomRender}
          toggleAddCollapse={(): void => {
            let sData = { ...selectData };
            sData.isEditing = false;
            sData.SelectData = initialSelectData;
            sData.tableId = -1;
            setSelectData(sData);
            setAddCollapse(!addCollapse);
          }}
          toggleEditCollapse={(index: number) => {
            const tempData = { ...data };
            let sData = { ...selectData };
            tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;

            sData.isEditing = true;
            sData.SelectData.groupName = tempData.rows[index].displayName;
            sData.SelectData.isCheckboxChecked =
              tempData.rows[index].mobileToTeams;
            sData.SelectData.mobileSelect1 =
              tempData.rows[index].mobileAvailability;
            sData.SelectData.mobileSelect2 =
              tempData.rows[index].mobileAvailability;
            sData.SelectData.mobileSelect3 =
              tempData.rows[index].mobileActivity;
            sData.SelectData.isSamwinCheckboxChecked =
              tempData.rows[index].samwinToTeams;
            sData.SelectData.samwinSelect1 =
              tempData.rows[index].samwinAvailability;
            sData.SelectData.samwinSelect2 =
              tempData.rows[index].samwinAvailability;
            sData.SelectData.samwinSelect3 =
              tempData.rows[index].samwinActivity;
            sData.tableId = tempData.rows[index];
            setSelectData(sData);
            setData(tempData);
          }}
          renderAddCollapse={(): ReactElement => {
            return renderForm(-1, false, []);
          }}
          renderEditCollapse={(index: number): ReactElement => {
            return renderForm(index, true, selectData);
          }}
        />
      </div>
    </>
  );
};

export default Groups;
