import React, { useState, useContext, useEffect, useCallback } from "react";
import CustomerBar from "./CustomerBar";
import { ApplicationContext } from "../context/ApplicationContext";
import Spinner from "./ui/Spinner";
import Card from "./ui/Card";
import CardBody from "./ui/CardBody";
import Progress from "./ui/Progress";
import { Doughnut } from "react-chartjs-2";
import { ReactComponent as ListIcon } from "../components/icons/List.svg";
import { ReactComponent as ListOlIcon } from "../components/icons/Listol.svg";

import "./PhoneDashboard.css";
import {
  ChartOptions,
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(Tooltip, Legend, ArcElement);

type DataSets = {
  data: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
};

type ChartData = {
  labels: string[];
  datasets: DataSets[];
};

type CloseUsage = {
  percentage: number;
  name: string;
  busyCount: number;
  totalCount: number;
};

type PhoneNumbersInfo = {
  totalRanges: number;
  totalNumbers: number;
  busyCount: number;
  freeCount: number;
  specialCount: number;
  noNumberCount: number;
  closeUsage: CloseUsage[];
};

const PhoneDashboard: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<PhoneNumbersInfo>({
    totalRanges: 0,
    totalNumbers: 0,
    busyCount: 0,
    freeCount: 0,
    specialCount: 0,
    noNumberCount: 0,
    closeUsage: [],
  });

  const [customerId, setCustomerId] = useState<number>(
    applicationContext.customerId
  );
  const [chartData, setChartData] = useState<ChartData>({
    labels: ["Free", "Busy", "No Number", "Special"],
    datasets: [
      {
        data: [0, 0, 0, 0],
        backgroundColor: ["green", "red", "purple", "yellow"],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });

  const [chartDataTwo, setChartDataTwo] = useState<ChartData>({
    labels: ["Auto Attendant", "Call Queue"],
    datasets: [
      {
        data: [0, 0],
        backgroundColor: ["green", "yellow"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(75, 192, 192, 1)"],
        borderWidth: 1,
      },
    ],
  });

  const chartOptions: ChartOptions<"doughnut"> = {
    indexAxis: "y",
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        position: "right",
      },
    },
    cutout: "70%",
    maintainAspectRatio: false,
  };

  const chartOptionsTwo: ChartOptions<"doughnut"> = {
    indexAxis: "y",
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        position: "right",
      },
    },
    cutout: "70%",
    maintainAspectRatio: false,
  };

  const loadDataForCustomer = useCallback(
    (id: number) => {
      setIsLoading(true);
      applicationContext.webApi
        ?.getPhoneNumbersInfo(id)
        .then((response) => {
          const dummyData = generateDummyData();
          setChartData({
            ...chartData,
            datasets: [
              {
                data: [
                  response.freeCount ?? 0,
                  response.busyCount ?? 0,
                  response.noNumberCount ?? 0,
                  response.specialCount ?? 0,
                ],
                backgroundColor: ["green", "red", "purple", "yellow"],
                borderColor: [
                  "rgba(255, 99, 132, 1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(75, 192, 192, 1)",
                ],
                borderWidth: 1,
              },
            ],
          });
          setChartDataTwo({
            ...chartDataTwo,
            datasets: [
              {
                data: [dummyData.autoAttendantCount, dummyData.callQueueCount],
                backgroundColor: ["yellow", "green"],
                borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
                borderWidth: 1,
              },
            ],
          });
          setData(response);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    },
    [applicationContext.webApi, chartData]
  );

  useEffect(() => {
    loadDataForCustomer(customerId);
  }, [customerId]);

  const generateDummyData = () => ({
    autoAttendantCount: 20,
    callQueueCount: 80,
  });

  useEffect(() => {
    setCustomerId(applicationContext.customerId);
  }, [applicationContext.customerId]);

  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = parseInt(event.target.value, 10);
    setCustomerId(id);
  };

  const renderCloseUsage = () => {
    return (
      <div className="w-100 p-3 flex-shrink-1">
        <div className="w-100 pb-4">
          <p className="text-primary phone-dashboard-font">
            Site Close To Full Usage
          </p>
        </div>
        <div className="table-responsive">
          <table className="table table-sm text-primary">
            <thead>
              <tr>
                <th style={{ width: "55%" }}>Site</th>
                <th style={{ width: "13%" }}>Used/All</th>
                <th style={{ width: "32%" }}>Usage</th>
              </tr>
            </thead>
            <tbody>
              {data.closeUsage
                ? data.closeUsage.map((site: any) => {
                    let color = "success";
                    if (site.percentage > 80) {
                      color = "danger";
                    }
                    if (site.percentage < 80 && site.percentage > 50) {
                      color = "warning";
                    }

                    return (
                      <tr key={"site_" + site.name}>
                        <td>{site.name}</td>
                        <td>{site.busyCount + "/" + site.totalCount}</td>
                        <td className="align-middle">
                          <Progress value={site.percentage} color={color}>
                            {site.percentage + "%"}
                          </Progress>
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    if (isLoading) {
      return (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="row">
            <Spinner className="text-primary" />
          </div>
          <div className="row">
            <small className="text-primary">
              <br />
              Loading data...
            </small>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="d-flex align-items-center justify-content-between p-1">
            <div className="d-flex flex-column flex-md-row justify-content-center w-100">
              <div className="d-flex flex-row align-items-center rounded-4 border border-primary shadow mb-3 mb-md-0 me-md-3 w-100">
                <div className="p-3">
                  <ListIcon
                    style={{ color: "gray", height: "32px", width: "32px" }}
                  />
                </div>
                <div className="p-3">
                  <strong style={{ color: "text-primary" }}>
                    {data.totalRanges ? data.totalRanges : 0}
                  </strong>
                  <br />
                  <small style={{ color: "grey" }}>Phone Number Ranges</small>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center rounded-4 border border-primary shadow mb-3 mb-md-0 me-md-3 w-100">
                <div className="p-3">
                  <ListOlIcon
                    style={{ color: "gray", height: "32px", width: "32px" }}
                  />
                </div>
                <div className="p-3">
                  <strong style={{ color: "text-primary" }}>
                    {(data.busyCount ? data.busyCount : 0) +
                      "/" +
                      (data.totalNumbers ? data.totalNumbers : 0)}
                  </strong>
                  <br />
                  <small style={{ color: "grey" }}>
                    Phone Numbers (Used/Total)
                  </small>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center rounded-4 border border-primary shadow w-100">
                <div className="p-3">
                  <ListOlIcon
                    style={{ color: "gray", height: "32px", width: "32px" }}
                  />
                </div>
                <div className="p-3">
                  <strong style={{ color: "text-primary" }}>0</strong>
                  <br />
                  <small style={{ color: "grey" }}>
                    Unsynchronized Phone Numbers
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column flex-md-row pt-3">
            <div className="me-md-3 mb-3 mb-md-0 p-3 rounded-4 border border-primary shadow w-md-50">
              <div className="d-flex flex-row p-3">
                <strong className="text-primary phone-dashboard-font">
                  Assigned numbers
                </strong>
              </div>
              <div className="d-flex flex-row">
                <Doughnut data={chartData} options={chartOptions} />
              </div>
            </div>
            <div className="p-3 rounded-4 border border-primary shadow w-md-50">
              <div className="d-flex flex-row p-3">
                <strong className="text-primary phone-dashboard-font">
                  Teams telephony
                </strong>
              </div>
              <div className="d-flex flex-row">
                <Doughnut data={chartDataTwo} options={chartOptionsTwo} />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row w-100 pl-3 pt-3">
            {renderCloseUsage()}
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar name="Dashboard" onCustomerChange={handleCustomerChange} />
      </div>
      <Card className="rounded-4 border-primary shadow m-0">
        <CardBody>{renderBody()}</CardBody>
      </Card>
    </>
  );
};

export default PhoneDashboard;
