import React from "react";

import "./Layout.css";
import SideBar from "./SideBar";
import AppNavBar from "./AppNavBar";

const Layout: React.FC = () => {
  return (
    <div className="d-flex flex-row">
      <div className="d-none d-lg-flex">
        <SideBar />
      </div>
      <div className="d-none d-lg-flex w-100">
        <div className="layout-container-right d-flex flex-column bg-primary w-100 vh-100">
          <AppNavBar />
        </div>
      </div>
      <div className="d-lg-none d-flex flex-column bg-primary w-100 vh-100">
        <AppNavBar />
      </div>
    </div>
  );
};

export default Layout;
