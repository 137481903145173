import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  ReactElement,
} from "react";
import Table, { TableData, TableInfo } from "./ui/Table";
import { ApplicationContext } from "../context/ApplicationContext";
import CustomerBar from "./CustomerBar";
import Card from "./ui/Card";
import CardBody from "./ui/CardBody";
import CardFooter from "./ui/CardFooter";
import Icon from "./ui/Icon";
import SelectSearch, { Option } from "./ui/SelectSearch";
import "./Groups.css";

const GroupUsers: React.FC = () => {
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };
  const applicationContext = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [addCollapse, setAddCollapse] = useState(false);
  const [frmError, setFrmError] = useState(false);
  const [data, setData] = useState<TableData>(initialData);
  const [groupData, setGroupData] = useState<TableData>(initialData);
  const [group, setGroup] = useState<string>("-1");
  const [customerId, setCustomerId] = useState<number>(
    applicationContext.webApi?.isSuperAdmin()
      ? applicationContext.customerId
      : applicationContext.webApi?.getCredentials()?.customer ?? -1
  );
  const [selectedOption, setSelectedOption] = useState<string | number>("");
  const [selectedLabel, setSelectedLabel] = useState("");
  const [options, setOptions] = useState<Option[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [removeId, setRemoveId] = useState<number>(-1);
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });

  const clearSearch = () => {
    setTableInfo({ ...tableInfo, search: "" });
  };

  const loadUserData = useCallback(
    (currentPage: number, step: number, search: string) => {
      if (group !== "-1") {
        setIsLoading(true);
        applicationContext.webApi
          ?.getGroupUsers(customerId, group, currentPage, step, search)
          .then((response) => {
            setIsLoading(false);
            setData(response);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      }
    },
    [group, customerId, applicationContext.webApi]
  );

  const loadGroupData = useCallback(
    (customerId: number) => {
      applicationContext.webApi
        ?.getGroups(customerId, 1, 100000, "")
        .then((response) => {
          setGroupData(response);
          applicationContext.webApi
            ?.getUsers(customerId, 1, 100000, "")
            .then((response) => {
              const fullNameOptions = response.rows.map((user: any) => ({
                value: user.id,
                label: `${user.firstName} ${user.lastName}`,
              }));
              setOptions(fullNameOptions);
            })
            .catch((error) => {
              console.log(error);
              setSelectedLabel("There are no users for the selected Customer.");
              setOptions([]);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [applicationContext.webApi]
  );

  useEffect(() => {
    setCustomerId(applicationContext.customerId);
    loadGroupData(applicationContext.customerId);
    setIsLoading(false);
    setSearchTerm("");
    setSelectedLabel("No user selected.");
    clearSearch();
  }, [applicationContext.customerId]);

  useEffect(() => {
    loadUserData(1, tableInfo.step, tableInfo.search);
  }, [group, customerId]);

  useEffect(() => {
    loadGroupData(customerId);
  }, [customerId, applicationContext.webApi]);

  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = parseInt(event.target.value, 10);
    setCustomerId(id);
    setSelectedLabel("No user selected.");
    setSearchTerm("");
  };

  const submitEditHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (event.currentTarget.reportValidity()) {
      console.log("add");
    } else {
      setFrmError(true);
    }
  };

  const handleClose = () => {
    setAddCollapse(!addCollapse);
  };

  const handleOptionSelection = (option: Option) => {
    setSelectedOption(option.value);
    setSelectedLabel(option.label);
    console.log(selectedOption);
  };

  const handleGroupsSelection = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setGroup(event.target.value);
  };

  const renderForm = () => {
    return (
      <form
        className={"needs-validation" + (frmError ? " was-validated" : "")}
        onSubmit={(event) => {
          submitEditHandler(event);
        }}
        noValidate
      >
        <Card className="bg-secondary bg-opacity-25 m-2">
          <CardBody>
            <div className="d-flex flex-column flex-md-row align-items-center">
              <div>
                <SelectSearch
                  options={options}
                  onSelect={handleOptionSelection}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              </div>
              <div className="d-flex pe-3 ps-3 text-primary">
                Selected User: {selectedLabel ?? "No option selected."}
              </div>
            </div>
          </CardBody>
          <CardFooter className="bg-secondary bg-opacity-25">
            <div className="d-flex flex-wrap justify-content-between">
              <div className="me-1 me-md-3 mb-2 mb-md-0">
                <button
                  type="submit"
                  className="btn btn-sm btn-primary user-edit-button m-1"
                >
                  Add
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary user-edit-button m-1"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </CardFooter>
        </Card>
      </form>
    );
  };

  const groupList = (): ReactElement => {
    return (
      <div className="d-flex flex-row align-items-center">
        <div>
          <select
            className="form-select form-select-sm text-primary"
            name="groups"
            value={group}
            onChange={handleGroupsSelection}
          >
            <option value="-1">Group List</option>
            {groupData.rows.map((group: any) => (
              <option key={group.id} value={group.id}>
                {group.displayName}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  const removeRow = (row_id: number) => {
    console.log(row_id);
  };

  const rowCustomRender = (i: number, j: number) => {
    let column = data.columns[j];
    let row: any = data.rows[i];
    if (column.field === "deletebtn") {
      return (
        <div className="d-flex align-content-center justify-content-center">
          <button
            className="btn btn-sm p-0"
            data-bs-toggle="modal"
            data-bs-target="#myModal"
            onClick={() => setRemoveId(row.id)}
          >
            <i className="bi bi-trash">
              {" "}
              <Icon name="delete_icon" />
            </i>{" "}
            Remove
          </button>
        </div>
      );
    } else {
      return row[column.field] === null ? "" : row[column.field].toString();
    }
  };

  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar
          name="Users in Groups"
          onCustomerChange={handleCustomerChange}
        />
      </div>
      <div className="modal fade" id="myModal" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="deleteModalLabel">
                Remove User
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Do you want to remove {removeId} from Group?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => removeRow(removeId)}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
      <Table
        className="table table-sm"
        data={data}
        loadData={loadUserData}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
        responsive
        bordered
        renderHeader={groupList}
        isLoading={isLoading}
        addCollapse={addCollapse}
        rowCustomRender={rowCustomRender}
        toggleAddCollapse={(): void => {
          setAddCollapse(!addCollapse);
        }}
        renderAddCollapse={(): ReactElement => {
          return renderForm();
        }}
      />
    </>
  );
};

export default GroupUsers;
