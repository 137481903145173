import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  FormEvent,
} from "react";
import { ApplicationContext } from "../context/ApplicationContext";
import "./MFAuthentication.css";

const MFAuthentication: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const [uri, setUri] = useState("");
  const [secret, setSecret] = useState("");
  const [currentSecret, setCurrentSecret] = useState("NAN");
  const [input, setInput] = useState<{ [key: string]: string }>({});
  const [formError, setFormError] = useState(false);
  const [verifyError, setVerifyError] = useState(false);

  const QRCode = require("qrcode");
  const currentUser = applicationContext.webApi?.getCredentials()?.email ?? "";

  useEffect(() => {
    applicationContext.webApi?.getSecret().then((response) => {
      if (response.secret && response.secret.length > 0) {
        setCurrentSecret(response.secret);
      } else {
        setCurrentSecret("");
        applicationContext.webApi?.createSecret().then((data) => {
          if (currentUser) {
            QRCode.toDataURL(
              `otpauth://totp/Alectoo:${currentUser}?secret=${data.secret}&digits=6`
            ).then((url: any, error: any) => {
              console.log(error);
              setUri(url);
            });
            setSecret(data.secret);
          }
        });
      }
    });
  }, []);

  const renderQRCode = () => {
    if (uri.length > 0 && secret.length > 0) {
      return (
        <img
          src={uri}
          alt="QRCode"
          style={{ height: "250px", width: "250px" }}
        />
      );
    } else {
      return <p>Enable Authentication Failed</p>;
    }
  };

  const handleInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let tempInput = Object.assign({}, input);
    tempInput = Object.assign(tempInput, { [event.target.id]: value });
    setInput(tempInput);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (event.currentTarget.reportValidity()) {
      const code = input["verify"];
      applicationContext.webApi?.verifyCode(secret, code).then((response) => {
        if (response?.success) {
          setVerifyError(false);
          setFormError(false);
          applicationContext.webApi?.updateSecret(secret).then(() => {
            setCurrentSecret(secret);
          });
        } else {
          setFormError(false);
          setVerifyError(true);
          console.log(verifyError);
        }
      });
    } else {
      setVerifyError(false);
      setFormError(true);
    }
  };

  const handleDisable = () => {
    if (applicationContext.webApi?.deleteSecret()) {
      setCurrentSecret("");
      setInput({ "verify": "" });
      applicationContext.webApi?.createSecret().then((data) => {
        if (currentUser) {
          QRCode.toDataURL(
            `otpauth://totp/Alectoo:${currentUser}?secret=${data.secret}&digits=6`
          ).then((url: any, error: any) => {
            console.log(error);
            setUri(url);
          });
          setSecret(data.secret);
        }
      });
    }
  };

  const renderError = () => {
    return <span className="text-danger">Invalid Code!</span>;
  };

  if (currentSecret.length === 0) {
    return (
      <>
        <div className="d-flex flex-column border border-primary rounded shadow mb-3 mt-3 pl-3 pr-3">
          <div className="mf-auth-container-text">
            <h2 className="text-primary mb-4 mt-3">Enable Authenticator</h2>
            <p className="text-primary text-break">
              To use an authenticator app go through the following steps:
            </p>
            <ol className="list text-primary">
              <li>
                <p>
                  Download a two-factor authenticator app like Microsoft
                  Authenticator for &nbsp;
                  <a
                    href="https://go.microsoft.com/fwlink/?Linkid=825071"
                    target="_blank"
                  >
                    Windows Phone
                  </a>
                  ,&nbsp;
                  <a
                    href="https://go.microsoft.com/fwlink/?Linkid=825072"
                    target="_blank"
                  >
                    Android
                  </a>{" "}
                  and&nbsp;
                  <a
                    href="https://go.microsoft.com/fwlink/?Linkid=825073"
                    target="_blank"
                  >
                    iOS
                  </a>{" "}
                  or Google Authenticator for&nbsp;
                  <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en"
                    target="_blank"
                  >
                    Android
                  </a>{" "}
                  and&nbsp;
                  <a
                    href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
                    target="_blank"
                  >
                    iOS
                  </a>
                  .
                </p>
              </li>
              <li className="mb-1">
                <p>
                  Scan the QR Code or enter this key <kbd>{secret}</kbd> into
                  your two factor authenticator app. Spaces and casing do not
                  matter.
                </p>
                <div className="align-item-center">{renderQRCode()}</div>
              </li>
              <li>
                <p>
                  Once you have scanned the QR code or input the key above, your
                  two factor authentication app will provide you with a unique
                  code. Enter the code in the confirmation box below.
                </p>
                <div className="d-flex flex-row">
                  <form
                    className={
                      "needs-validation" + (formError ? " was-validated" : "")
                    }
                    onSubmit={(event) => {
                      handleSubmit(event);
                    }}
                    noValidate
                  >
                    <label className="control-label pe-3 mb-1" htmlFor="verify">
                      Verification Code
                    </label>
                    <div className="mb-3">
                      <input
                        className="form-control text-primary mf-auth-input"
                        id="verify"
                        type="text"
                        onChange={handleInputOnChange}
                        onKeyDown={(event) => {
                          let keyCode = event.key.charCodeAt(0);
                          if (
                            (keyCode > 47 && keyCode < 58) ||
                            (keyCode > 64 && keyCode < 69)
                          ) {
                            return true;
                          }

                          event.preventDefault();
                        }}
                        name="verify"
                        value={input["verify"] || ""}
                        minLength={6}
                        maxLength={6}
                        required
                      />
                      <span className="invalid-feedback">
                        The Verification Code must be 6 characters long.
                      </span>
                      {verifyError ? renderError() : null}
                    </div>
                    <button
                      className="btn btn-primary mf-auth-button m-0"
                      type="submit"
                      color="primary"
                    >
                      Verify
                    </button>
                  </form>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </>
    );
  } else if (currentSecret === "NAN") {
    return <div />;
  } else {
    return (
      <div className="d-flex flex-column mf-auth-container mb-3 mt-3 pl-3 pr-3">
        <div className="mf-auth-container-text mt-3 mb-3">
          <h5>Authenticator is enabled for this account!</h5>
          <button className="btn btn-danger" onClick={handleDisable}>
            Disable
          </button>
        </div>
      </div>
    );
  }
};

export default MFAuthentication;
