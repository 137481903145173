import React, { ReactElement, useState, useEffect, useCallback } from "react";
import CardBody from "./CardBody";
import Card from "./Card";
import Spinner from "./Spinner";
import CardHeader from "./CardHeader";
import CardFooter from "./CardFooter";
import InputGroup from "./InputGroup";
import Pagination from "./Pagination";
import PaginationItem from "./PaginationItem";
import PaginationLink from "./PaginationLink";
import { ReactComponent as PlusIcon } from "../icons/Plus.svg";
import Collapse from "./Collapse";
import { TabletMobile, Desktop } from "../../utils/breakpoints";

export type TableInfo = {
  currentPage: number;
  step: number;
  search: string;
};

interface Column {
  label: string;
  field: string;
  width: string;
}

export interface TableData {
  total: number;
  page: number;
  limit: number;
  columns: Column[];
  rows: any[];
}

export interface TableProps {
  bordered?: boolean;
  className?: string;
  responsive?: boolean;
  style?: React.CSSProperties;
  isLoading: boolean;
  data: TableData;
  addCollapse?: boolean;
  toggleAddCollapse?: () => void;
  toggleEditCollapse?: (index: number) => void;
  loadData?: (page: number, limit: number, search: string) => void;
  rowCustomRender?: (row: number, column: number) => ReactElement;
  renderEditCollapse?: (index: number) => ReactElement;
  renderAddCollapse?: () => ReactElement;
  renderHeader?: () => ReactElement;
  tableInfo: TableInfo;
  setTableInfo: React.Dispatch<React.SetStateAction<TableInfo>>;
}

var timeoutId: NodeJS.Timeout | undefined = undefined;

const Table: React.FC<TableProps> = ({ tableInfo, setTableInfo, ...props }) => {
  const [pagination, setPagination] = useState({
    currentPage: props.data.page ?? 1,
    lastPage: Math.ceil(props.data.total / (props.data.limit ?? 10)),
    step: props.data.limit ?? 10,
    firstLinkPage: 1,
    from: 1,
    to: 1,
  });

  let className: string;
  const loadData = props.loadData; //this avoids warning of not including props in useEffect dependency array

  if (props.bordered) {
    className =
      (props?.className?.length ?? 0) > 0
        ? props.className + " table-bordered border-primary mb-0"
        : props.className + "table-bordered border-primary mb-0";
  } else {
    className = props.className ?? "table";
  }

  const calculateFirstPage = useCallback(
    (firstPage: number, firstLinkPage: number): number => {
      let retVal = firstPage;

      if (firstPage > 0) {
        if (firstPage < 3 && firstPage > 0) {
          retVal = firstLinkPage;
        } else if (firstPage === 3) {
          retVal = props.data.page;
        } else if (firstPage > 3) {
          retVal = props.data.page - 3;
        } else if (firstPage < 0) {
          retVal = props.data.page;
        }
      } else if (firstPage <= 0) {
        retVal = props.data.page;
      } else {
        retVal = 1;
      }

      return retVal;
    },
    [props.data]
  );

  useEffect(() => {
    props.data.rows = props.data.rows?.map((row) => {
      row.isExpanded = false;
      return row;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //needs to be empty to execute only once

  useEffect(() => {
    loadData?.(pagination.currentPage, pagination.step, tableInfo.search ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage, pagination.step, tableInfo.search, loadData]);

  useEffect(() => {
    if (props.data) {
      let firstPage = calculateFirstPage(
        props.data.page - pagination.firstLinkPage,
        pagination.firstLinkPage
      );

      let newPagination = {
        currentPage: props.data.page > 0 ? props.data.page : 1,
        lastPage:
          props.data.total > 0
            ? Math.ceil(props.data.total / props.data.limit)
            : 1,
        firstLinkPage: firstPage,
        from:
          props.data.limit !== 0
            ? props.data.page * props.data.limit - props.data.limit + 1
            : 0,
        to: props.data.limit * props.data.page,
        step: pagination.step,
        // search: tableInfo.search,
      };

      newPagination.to =
        newPagination.currentPage === newPagination.lastPage
          ? props.data.total
          : newPagination.to;
      setPagination(newPagination);
    }
  }, [
    props.data,
    calculateFirstPage,
    pagination.firstLinkPage,
    pagination.step,
  ]);

  const renderColumns = (): ReactElement[] => {
    if (props?.data?.columns) {
      let columns = props.data.columns.slice();
      return columns.map((column) => {
        return (
          <th
            className="pt-1 pb-1 bg-secondary bg-opacity-50"
            style={{ width: column.width, fontSize: "0.9rem", fontWeight: 400 }}
            key={column.label}
          >
            {column.label}
          </th>
        );
      });
    } else {
      return [<th key="TABLE_HEADER_KEY"></th>];
    }
  };

  const renderRows = (): ReactElement[] => {
    if (props?.data?.columns && props.data.rows) {
      let columns = props.data.columns.slice();
      let rows = props.data.rows;

      return rows.map((row, rowIndex) => {
        let style: React.CSSProperties =
          rowIndex % 2 !== 0
            ? { backgroundColor: "rgb(186, 215, 233, 0.5)" }
            : {};

        if (props.renderEditCollapse) {
          style =
            rowIndex % 2 !== 0
              ? {
                  backgroundColor: "rgb(186, 215, 233, 0.5)",
                  cursor: "pointer",
                }
              : { cursor: "pointer" };
        }

        return (
          <React.Fragment key={"fragment_" + row["id"]}>
            <tr
              key={row["id"] ?? ""}
              style={style}
              onClick={() => {
                if (props.renderEditCollapse) {
                  props.toggleEditCollapse?.(rowIndex);
                  props.data.rows = props.data.rows.map((tempRow) => {
                    if (tempRow !== props.data.rows[rowIndex])
                      tempRow.isExpanded = false;
                    return tempRow;
                  });
                }
              }}
            >
              {columns.map((column, columnIndex) => {
                if (props.rowCustomRender) {
                  return (
                    <td
                      key={row["id"] + "-" + column.field}
                      className="pt-1 pb-1 text-truncate"
                      style={{ fontSize: "0.9rem", fontWeight: 300 }}
                    >
                      {props.rowCustomRender(rowIndex, columnIndex)}
                    </td>
                  );
                } else {
                  return (
                    <td
                      key={row["id"] + "-" + column.field}
                      className="pt-1 pb-1 text-truncate"
                      style={{ fontSize: "0.9rem", fontWeight: 300 }}
                    >
                      {row[column.field] || ""}
                    </td>
                  );
                }
              })}
            </tr>
            <tr
              className="bg-white"
              id={"collapsable_" + rowIndex}
              style={
                rows[rowIndex].isExpanded
                  ? { display: "table-row" }
                  : { display: "none" }
              }
            >
              <td colSpan={columns.length} style={{ padding: "0" }}>
                <Collapse isExpanded={rows[rowIndex].isExpanded}>
                  {props.renderEditCollapse?.(rowIndex)}
                </Collapse>
              </td>
            </tr>
          </React.Fragment>
        );
      });
    } else {
      return [<tr key="KEY_EMPTY_TR"></tr>];
    }
  };

  const renderTable = (): ReactElement => {
    if (!props.isLoading) {
      if (props?.data?.rows?.length > 0) {
        return props.responsive ? (
          <div className="table-responsive">
            <table className={className} style={props.style}>
              <thead>
                <tr key="column">{renderColumns()}</tr>
              </thead>
              <tbody>{renderRows()}</tbody>
            </table>
          </div>
        ) : (
          <table className={className} style={props.style}>
            <thead>
              <tr key="column">{renderColumns()}</tr>
            </thead>
            <tbody>{renderRows()}</tbody>
          </table>
        );
      } else {
        return (
          <div
            className="d-flex flex-row justify-content-center border 
          border-primary border-top-0 border-bottom-0"
          >
            <small>There are no items to show...</small>
          </div>
        );
      }
    } else {
      return (
        <div
          className="d-flex flex-column align-items-center justify-content-center h-100 w-100 border 
        border-primary border-top-0 border-bottom-0"
        >
          <div className="row">
            <Spinner className="text-primary" />
          </div>
          <div className="row">
            <small className="text-primary">
              <br />
              Loading data...
            </small>
          </div>
        </div>
      );
    }
  };

  const handleStepChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let target = event.target as HTMLSelectElement;
    let numEntries = Number(event.target.value) || 10;

    target.blur();

    if (numEntries === pagination.step) return;

    setPagination({ ...pagination, step: numEntries });
  };

  const handleOnSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setTableInfo({ ...tableInfo, search: newSearchTerm });

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {}, 500);
  };

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage < 1 || newPage > pagination.lastPage) return;

      setPagination({ ...pagination, currentPage: newPage });
      loadDataForPage(newPage);
    },
    [pagination, setPagination]
  );

  const loadDataForPage = (page: number) => {
    const { step } = pagination;
    props.loadData?.(page, step, tableInfo.search);
  };

  const renderPagination = () => {
    let pages = [];
    const pageLimit = 8;
    let startPage, endPage;

    if (pagination.lastPage <= pageLimit) {
      startPage = 1;
      endPage = pagination.lastPage;
    } else {
      const maxPagesBeforeCurrentPage = Math.floor(pageLimit / 2);
      const maxPagesAfterCurrentPage = Math.ceil(pageLimit / 2) - 1;
      if (pagination.currentPage <= maxPagesBeforeCurrentPage) {
        // Close to the start
        startPage = 1;
        endPage = pageLimit;
      } else if (
        pagination.currentPage + maxPagesAfterCurrentPage >=
        pagination.lastPage
      ) {
        // Close to the end
        startPage = pagination.lastPage - pageLimit + 1;
        endPage = pagination.lastPage;
      } else {
        // Somewhere in the middle
        startPage = pagination.currentPage - maxPagesBeforeCurrentPage;
        endPage = pagination.currentPage + maxPagesAfterCurrentPage;
      }
    }

    // Previous Page Link
    if (pagination.currentPage > 1) {
      pages.unshift(
        <PaginationItem key="prevPage">
          <PaginationLink
            onClick={() => handlePageChange(pagination.currentPage - 1)}
          >
            ‹
          </PaginationLink>
        </PaginationItem>
      );
    }
    // First Page Link
    if (startPage > 1) {
      pages.unshift(
        <PaginationItem key="firstPage">
          <PaginationLink onClick={() => handlePageChange(1)}>«</PaginationLink>
        </PaginationItem>
      );
    }

    // Create page links
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <PaginationItem key={i} active={i === pagination.currentPage}>
          <PaginationLink
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              let target = event.target as HTMLButtonElement;
              target.blur();
              handlePageChange(i);
            }}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    if (endPage < pagination.lastPage) {
      if (endPage < pagination.lastPage - 1) {
        pages.push(
          <PaginationItem key="dots">
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        );
      }

      // Add the last page
      pages.push(
        <PaginationItem
          key={pagination.lastPage}
          active={pagination.lastPage === pagination.currentPage}
        >
          <PaginationLink onClick={() => handlePageChange(pagination.lastPage)}>
            {pagination.lastPage}
          </PaginationLink>
        </PaginationItem>
      );
    }

    // Next Page Link
    if (pagination.currentPage < pagination.lastPage) {
      pages.push(
        <PaginationItem key="nextPage">
          <PaginationLink
            onClick={() => handlePageChange(pagination.currentPage + 1)}
          >
            ›
          </PaginationLink>
        </PaginationItem>
      );
    }

    // Last Page Link
    if (endPage < pagination.lastPage) {
      pages.push(
        <PaginationItem key="lastPage">
          <PaginationLink onClick={() => handlePageChange(pagination.lastPage)}>
            »
          </PaginationLink>
        </PaginationItem>
      );
    }

    return <Pagination ariaLabel="Page navigation">{pages}</Pagination>;
  };

  const renderMobilePagination = () => {
    let pages = [];
    const pageLimit = 2;
    let startPage, endPage;

    if (pagination.lastPage <= pageLimit) {
      startPage = 1;
      endPage = pagination.lastPage;
    } else {
      const maxPagesBeforeCurrentPage = Math.floor(pageLimit / 2);
      const maxPagesAfterCurrentPage = Math.ceil(pageLimit / 2) - 1;
      if (pagination.currentPage <= maxPagesBeforeCurrentPage) {
        // Close to the start
        startPage = 1;
        endPage = pageLimit;
      } else if (
        pagination.currentPage + maxPagesAfterCurrentPage >=
        pagination.lastPage
      ) {
        // Close to the end
        startPage = pagination.lastPage - pageLimit + 1;
        endPage = pagination.lastPage;
      } else {
        // Somewhere in the middle
        startPage = pagination.currentPage - maxPagesBeforeCurrentPage;
        endPage = pagination.currentPage + maxPagesAfterCurrentPage;
      }
    }

    // Previous Page Link
    if (pagination.currentPage > 1) {
      pages.unshift(
        <PaginationItem key="prevPage">
          <PaginationLink
            onClick={() => handlePageChange(pagination.currentPage - 1)}
          >
            ‹
          </PaginationLink>
        </PaginationItem>
      );
    }
    // First Page Link
    if (startPage > 1) {
      pages.unshift(
        <PaginationItem key="firstPage">
          <PaginationLink onClick={() => handlePageChange(1)}>«</PaginationLink>
        </PaginationItem>
      );
    }

    // Create page links
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <PaginationItem key={i} active={i === pagination.currentPage}>
          <PaginationLink
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              let target = event.target as HTMLButtonElement;
              target.blur();
              handlePageChange(i);
            }}
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    if (endPage < pagination.lastPage) {
      if (endPage < pagination.lastPage - 1) {
        pages.push(
          <PaginationItem key="dots">
            <PaginationLink>..</PaginationLink>
          </PaginationItem>
        );
      }

      // Add the last page
      pages.push(
        <PaginationItem
          key={pagination.lastPage}
          active={pagination.lastPage === pagination.currentPage}
        >
          <PaginationLink onClick={() => handlePageChange(pagination.lastPage)}>
            {pagination.lastPage}
          </PaginationLink>
        </PaginationItem>
      );
    }

    // Next Page Link
    if (pagination.currentPage < pagination.lastPage) {
      pages.push(
        <PaginationItem key="nextPage">
          <PaginationLink
            onClick={() => handlePageChange(pagination.currentPage + 1)}
          >
            ›
          </PaginationLink>
        </PaginationItem>
      );
    }

    return <Pagination ariaLabel="Page navigation">{pages}</Pagination>;
  };

  return (
    <Card className="shadow mt-3">
      <CardHeader className="bg-white border border-primary border-bottom-0">
        <div className="d-flex flex-md-row flex-column justify-content-md-between align-items-start">
          <div className="d-flex flex-row align-items-center me-3 mb-2">
            <small className="text-primary">Show&nbsp;</small>
            <select
              className="form-select form-select-sm text-primary"
              onChange={handleStepChange}
              value={pagination.step}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <small className="text-primary">&nbsp;entries</small>
          </div>
          <div className="ps-0 mb-2">
          {props.renderHeader ? props.renderHeader() : null}
          </div>
          <InputGroup style={{ width: "12rem" }} className="ps-md-3 ps-0">
            <input
              className="form-control form-control-sm"
              placeholder="Search..."
              onChange={handleOnSearchChange}
              value={tableInfo.search}
            ></input>
            {props.renderAddCollapse && (
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  props.toggleAddCollapse?.();
                }}
              >
                <PlusIcon
                  fill="white"
                  style={{ height: "24px", width: "24px" }}
                />
              </button>
            )}
          </InputGroup>
        </div>
      </CardHeader>
      <CardBody className="p-0">
        <Collapse
          className="border border-primary border-bottom-0"
          isExpanded={props.addCollapse ? props.addCollapse : false}
        >
          {props.renderAddCollapse?.()}
        </Collapse>
        {renderTable()}
      </CardBody>
      <CardFooter className="bg-white border border-primary border-top-0">
        <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center pe-1 w-100 justify-content-between">
          <div className="mb-3">
            <small className="text-primary">
              Showing {pagination.from ?? 0} to {pagination.to ?? 0} of{" "}
              {props?.data?.total && props.data.total > 0
                ? props.data.total
                : "0"}
            </small>
          </div>
          <div className="text-sm">
            <Desktop>{renderPagination()}</Desktop>
            <TabletMobile>{renderMobilePagination()}</TabletMobile>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};

export default Table;
