export type LoginResponse = {
  token: string;
  name: string;
  email: string;
  customer: number;
};

export type CustomerResponse = {
  id: number;
  name: string;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  customerOfId: number;
  customers: CustomerResponse[];
  serviceActivity: string;
};

export type PhoneNumber = {
  id: number;
  customerId: number;
  displayName: string;
  number: string;
  endpoint: string;
  comment: string;
  special: boolean;
};

export type User = {
  id: number;
  firstName: string;
  lastName: string;
  userName: string;
  password: string;
  email: string;
  roleId: number;
  customerId: number;
};

export type Customer = {
  id: number;
  name: string;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  customerOfId: number;
};

export type PhoneRange = {
  id: number;
  customerId: number;
  country: string;
  site: string;
  areaZip: string;
  numberFrom: string;
  numberTo: string;
  address: string;
};

export type PhoneNumberSpecial = {
  customerId: number;
  special: string;
  page: number;
  limit: number;
  search: string;
  country: string;
  status: string;
  site: string;
  comment: string;
};

export type Monitoring = {
  customerId: number;
  emails: string;
  serviceInactivityEmail: boolean;
  teamsInactivityEmail: boolean;
  mobileInactivityEmail: boolean;
};

export interface IApplicationApi {
  getCredentials(): LoginResponse | null;
  isSuperAdmin(): boolean;
  isAdmin(): boolean;
  isUser(): boolean;
  isPartner(): boolean;
  login: (
    username: string,
    password: string,
    code: string,
    rememberMe: boolean
  ) => Promise<LoginResponse>;
  forgotPassword: (email: string) => Promise<void>;
  changePassword: (token: string, password: string) => Promise<void>;
  getCustomers: (
    page: number,
    limit: number,
    search: string,
    includeCustomers: boolean
  ) => Promise<CustomerResponse>;
  logout: () => Promise<void>;
  getCustomersOf(customerId: number): Promise<CustomerResponse[]>;
  getPresence: (
    customerId: number,
    page: number,
    limit: number,
    search: string
  ) => Promise<any>;
  getUsers: (
    customerId: number,
    page: number,
    limit: number,
    search: string
  ) => Promise<any>;
  getMobileUsers: (
    customerId: number,
    page: number,
    limit: number,
    search: string
  ) => Promise<any>;
  getPhoneNumbers: (customerId: number) => Promise<any>;
  getTelephoneNumbers: (
    customerId: number,
    page: number,
    limit: number,
    search: string,
    country: string,
    status: string,
    site: string
  ) => Promise<any>;
  getHuntGroups: (
    customerId: number,
    page: number,
    limit: number,
    search: string
  ) => Promise<any>;
  getGroup: (groupId: string) => Promise<any>;
  saveGroup: (group: any) => Promise<void>;
  getGroups: (
    customerId: number,
    page: number,
    limit: number,
    search: string
  ) => Promise<any>;
  getGroupUsers(
    customerId: number,
    groupId: string,
    page: number,
    limit: number,
    search: string
  ): Promise<any>;
  getUsersInGroup: (groupId: number) => Promise<any>;
  updateUserIsOnline(
    groupId: number,
    userId: number,
    checked: boolean
  ): Promise<boolean>;
  getLicenses: (customerId: number) => Promise<any>;
  getPhoneRanges: (
    customerId: number,
    page: number,
    limit: number,
    search: string
  ) => Promise<any>;
  getCustomerIntegration(customerId: number): Promise<any>;
  getIntegrationUser(customerId: number): Promise<any>;
  saveIntegrationUser(
    customerId: number,
    userName: string,
    password: string
  ): Promise<void>;
  getSecret(): Promise<any>;
  createSecret(): Promise<any>;
  deleteSecret(): Promise<any>;
  updateSecret(secret: string): Promise<any>;
  verifyCode(secret: string, code: string): Promise<any>;
  getPhoneNumbersInfo(customerId: number): Promise<any>;
  upsertPhoneNumber(phoneNumber: PhoneNumber): Promise<void>;
  saveUser(user: User): Promise<void>;
  createUser(user: User): Promise<void>;
  deleteUser(userId: number): Promise<void>;
  savePhoneRange(phoneRange: PhoneRange): Promise<void>;
  createPhoneRange(phoneRange: PhoneRange): Promise<void>;
  deletePhoneRange(phoneRangeId: number): Promise<void>;
  saveCustomer(customer: Customer): Promise<void>;
  createCustomer(customer: Customer): Promise<void>;
  deleteCustomer(customerId: number): Promise<void>;
  savePhoneNumberSpecial(phoneNumberSpecial: PhoneNumberSpecial): Promise<void>;
  saveDomains(customerId: number, domains: string): Promise<void>;
  getDomains(customerId: number): Promise<any>;
  getMonitoring(customerId: number): Promise<Monitoring>;
  upsertMonitoring(monitoring: Monitoring): Promise<void>;
}

export class ApplicationApi<T extends IApplicationApi>
  implements IApplicationApi
{
  constructor(private apiService: T) {}

  isSuperAdmin(): boolean {
    return this.apiService.isSuperAdmin();
  }

  isAdmin(): boolean {
    return this.apiService.isAdmin();
  }

  isUser(): boolean {
    return this.apiService.isUser();
  }

  isPartner(): boolean {
    return this.apiService.isPartner();
  }

  getCredentials(): LoginResponse | null {
    return this.apiService.getCredentials();
  }

  async getCustomers(
    page: number,
    limit: number,
    search: string,
    includeCustomers: boolean
  ): Promise<any> {
    return this.apiService.getCustomers(page, limit, search, includeCustomers);
  }

  async getCustomersOf(customerId: number): Promise<CustomerResponse[]> {
    return this.apiService.getCustomersOf(customerId);
  }

  async getPresence(
    customerId: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return this.apiService.getPresence(customerId, page, limit, search);
  }

  async login(
    username: string,
    password: string,
    code: string,
    rememberMe: boolean
  ): Promise<LoginResponse> {
    return this.apiService.login(username, password, code, rememberMe);
  }

  async forgotPassword(email: string): Promise<void> {
    return this.apiService.forgotPassword(email);
  }

  async changePassword(token: string, password: string): Promise<void> {
    return this.apiService.changePassword(token, password);
  }

  async logout(): Promise<void> {
    return this.apiService.logout();
  }

  async getUsers(
    customerId: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return this.apiService.getUsers(customerId, page, limit, search);
  }

  async getMobileUsers(
    customerId: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return this.apiService.getMobileUsers(customerId, page, limit, search);
  }

  async getPhoneNumbers(customerId: number): Promise<any> {
    return this.apiService.getPhoneNumbers(customerId);
  }

  async getTelephoneNumbers(
    customerId: number,
    page: number,
    limit: number,
    search: string,
    country: string,
    status: string,
    site: string
  ): Promise<any> {
    return this.apiService.getTelephoneNumbers(
      customerId,
      page,
      limit,
      search,
      country,
      status,
      site
    );
  }
  async getHuntGroups(
    customerId: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return this.apiService.getHuntGroups(customerId, page, limit, search);
  }

  async getGroups(
    customerId: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return this.apiService.getGroups(customerId, page, limit, search);
  }

  async getGroupUsers(
    customerId: number,
    groupId: string,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return this.apiService.getGroupUsers(
      customerId,
      groupId,
      page,
      limit,
      search
    );
  }

  async getUsersInGroup(groupId: number): Promise<any> {
    return this.apiService.getUsersInGroup(groupId);
  }

  async updateUserIsOnline(
    groupId: number,
    userId: number,
    checked: boolean
  ): Promise<boolean> {
    return this.apiService.updateUserIsOnline(groupId, userId, checked);
  }

  async getLicenses(customerId: number): Promise<any> {
    return this.apiService.getLicenses(customerId);
  }

  async getPhoneRanges(
    customerId: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return this.apiService.getPhoneRanges(customerId, page, limit, search);
  }

  async getCustomerIntegration(customerId: number): Promise<any> {
    return this.apiService.getCustomerIntegration(customerId);
  }

  async getIntegrationUser(customerId: number): Promise<any> {
    return this.apiService.getIntegrationUser(customerId);
  }

  async saveIntegrationUser(
    customerId: number,
    userName: string,
    password: string
  ): Promise<void> {
    return this.apiService.saveIntegrationUser(customerId, userName, password);
  }

  async getSecret(): Promise<any> {
    return this.apiService.getSecret();
  }

  async createSecret(): Promise<any> {
    return this.apiService.createSecret();
  }

  async deleteSecret(): Promise<any> {
    return this.apiService.deleteSecret();
  }

  async updateSecret(secret: string): Promise<any> {
    return this.apiService.updateSecret(secret);
  }

  async verifyCode(secret: string, code: string): Promise<any> {
    return this.apiService.verifyCode(secret, code);
  }

  async getPhoneNumbersInfo(customerId: number): Promise<any> {
    return this.apiService.getPhoneNumbersInfo(customerId);
  }

  async upsertPhoneNumber(phoneNumber: PhoneNumber): Promise<void> {
    return this.apiService.upsertPhoneNumber(phoneNumber);
  }

  async saveUser(user: User): Promise<void> {
    return this.apiService.saveUser(user);
  }

  async createUser(user: User): Promise<void> {
    return this.apiService.createUser(user);
  }

  async deleteUser(userId: number): Promise<void> {
    return this.apiService.deleteUser(userId);
  }

  async savePhoneRange(phoneRane: PhoneRange): Promise<void> {
    return this.apiService.savePhoneRange(phoneRane);
  }

  async createPhoneRange(phoneRane: PhoneRange): Promise<void> {
    return this.apiService.createPhoneRange(phoneRane);
  }

  async deletePhoneRange(phoneRangeId: number): Promise<void> {
    return this.apiService.deletePhoneRange(phoneRangeId);
  }

  async saveCustomer(customer: Customer): Promise<void> {
    return this.apiService.saveCustomer(customer);
  }

  async createCustomer(customer: Customer): Promise<void> {
    return this.apiService.createCustomer(customer);
  }

  async deleteCustomer(customerId: number): Promise<void> {
    return this.apiService.deleteCustomer(customerId);
  }

  async savePhoneNumberSpecial(
    phoneNumberSpecial: PhoneNumberSpecial
  ): Promise<void> {
    return this.apiService.savePhoneNumberSpecial(phoneNumberSpecial);
  }

  async saveDomains(customerId: number, domains: string): Promise<void> {
    return this.apiService.saveDomains(customerId, domains);
  }

  async getDomains(customerId: number): Promise<any> {
    return this.apiService.getDomains(customerId);
  }

  async getMonitoring(customerId: number): Promise<any> {
    return this.apiService.getMonitoring(customerId);
  }

  async upsertMonitoring(monitoring: Monitoring): Promise<void> {
    return this.apiService.upsertMonitoring(monitoring);
  }

  async getGroup(groupId: string): Promise<any> {
    return this.apiService.getGroup(groupId);
  }

  async saveGroup(group: any): Promise<void> {
    return this.apiService.saveGroup(group);
  }
}
